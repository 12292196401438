"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var ap = __importStar(require("ap-react"));
var ap_react_1 = require("ap-react");
var React = __importStar(require("react"));
var receiveState_1 = require("../state/receiveState");
// Overwrite CSS
// This is temporarily, as we will be uplifting the entire design to follow the new style one day in the future
var inputTextClass = ap_react_1.typestyle.style({
    $nest: {
        '& label': {
            color: "black",
            fontWeight: 'bold'
        }
    }
});
var helpTextClass = ap_react_1.typestyle.style({
    hyphens: "manual",
});
var ReceiveMfaPinSentSection = /** @class */ (function (_super) {
    __extends(ReceiveMfaPinSentSection, _super);
    function ReceiveMfaPinSentSection(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    ReceiveMfaPinSentSection.prototype.render = function () {
        var _this = this;
        return (React.createElement(ap_react_1.Form, { onSubmit: function () {
                receiveState_1.receiveState.formState.validate().then(function (res) {
                    if (res.hasError)
                        return;
                    else {
                        receiveState_1.receiveState.verifyPin(__assign({}, _this.props.params, { pinCode: receiveState_1.receiveState.pinCode.$ }));
                    }
                });
            } },
            React.createElement(ap.Vertical, { spacing: 20 },
                React.createElement(ap_react_1.Paragraph, null,
                    " ",
                    receiveState_1.receiveState.instructionPinSentLabel,
                    " "),
                React.createElement(ap_react_1.TextInputField, { id: "pinCode", name: "pinCode", label: "Enter verification code", className: inputTextClass, star: false, fieldState: receiveState_1.receiveState.pinCode, onChange: function () { return receiveState_1.receiveState.clearRecoverableErrorMessage(); } }),
                React.createElement(ap.ContentHorizontal, null,
                    React.createElement(ap.Flex, null),
                    React.createElement(ap_react_1.SubmitInputPrimary, { id: "continueBtn", value: "Continue", disabled: receiveState_1.receiveState.formState.hasError, loading: receiveState_1.receiveState.formState.validating || receiveState_1.receiveState.committingVerify }))),
            React.createElement(ap_react_1.SmallVerticalSpace, { space: 35 }),
            React.createElement(ap.Vertical, { spacing: 15 },
                React.createElement(ap.ContentHorizontal, null, receiveState_1.receiveState.sendingPin
                    ? React.createElement(ap.Horizontal, { spacing: 10 },
                        React.createElement(ap_react_1.Paragraph, null, " Sending code"),
                        React.createElement(ap_react_1.Loader, null))
                    : React.createElement(ap_react_1.Paragraph, null,
                        "Didn't receive the code? ",
                        React.createElement("button", { id: "resendBtn", type: "button", className: ap_react_1.AnchorStyles.rootClassName, onClick: function () { return receiveState_1.receiveState.sendPin(_this.props.params); } }, "Resend code"))),
                React.createElement("p", { className: ap_react_1.typestyle.classes(ap_react_1.TxtStyles.paragraphClass, helpTextClass) }, "If you have any issues please contact 13POST (13 76 78)."))));
    };
    ReceiveMfaPinSentSection = __decorate([
        ap_react_1.observer
    ], ReceiveMfaPinSentSection);
    return ReceiveMfaPinSentSection;
}(React.Component));
exports.ReceiveMfaPinSentSection = ReceiveMfaPinSentSection;
