"use strict";
/**
 * @module Component free information about screen links in the application
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Use member functions to get some link
 */
exports.route = {
    /** General */
    styleguide: function () { return '/styleguide'; },
    /** App */
    landing: function () { return '/welcome'; },
    registerInterest: function () { return '/registerInterest'; },
    /** Sender */
    sendNew: function () { return '/sendNew'; },
    payment: function () { return '/payment'; },
    success: function () { return '/success'; },
    /** Survey monkey */
    surveyComplete: function () { return '/surveyComplete'; },
    /** Receiver */
    receiveMfa: function (transactionId, transferId) { return "/receive/mfa/" + transactionId + "/" + transferId; },
    receiveDocs: function (transactionId, transferId) { return "/receive/docs/" + transactionId + "/" + transferId; },
    /** Errors */
    error: function () { return '/error'; },
};
/**
 * For docs we don't need any special router setup and so they are seperate
 * Warning: Don't use `linkTo` or `navigateTo` for these. Use directly in Achors with target `_blank`
 */
exports.docs = {
    helpAndSupport: function () { return "https://auspost.com.au/help-and-support"; },
    termsAndConditions: function () { return "https://sdd.auspost.com.au/docs/termsAndConditions.pdf"; },
    landingPageTermsAndConditions: function () { return "https://auspost.com.au/about-us/about-our-site/website-terms-conditions"; },
    privacyPolicy: function () { return "https://sdd.auspost.com.au/docs/privacyPolicy.pdf"; }
};
exports.images = {
    hero: '/images/banner.png',
    secure: '/images/secure.svg',
    fast: '/images/fast.svg',
    affordable: '/images/affordable.svg',
    focus: '/images/focus.png',
    how: '/images/how.png',
    theireyes: '/images/theireyes.png',
};
