"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var config_1 = require("../config/config");
var page_1 = require("./base/page");
var ap_react_1 = require("ap-react");
var ap = __importStar(require("ap-react"));
var ap_react_2 = require("ap-react");
var ap_react_3 = require("ap-react");
var sendState_1 = require("../state/sendState");
var ap_react_4 = require("ap-react");
var appStepper_1 = require("./components/appStepper");
var ap_react_5 = require("ap-react");
var payhive_1 = require("./components/payhive");
var ap_react_6 = require("ap-react");
var routes_1 = require("../routing/routes");
var yester_1 = require("yester");
var Payment = /** @class */ (function (_super) {
    __extends(Payment, _super);
    function Payment() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Payment.prototype.render = function () {
        var sections = [
            React.createElement(ap_react_2.MaxWidthIsland, { key: "payment-maxwidth-island" },
                React.createElement(appStepper_1.AppStepper, { current: appStepper_1.AppProcessStep.Payment })),
            this.renderPaymentSection()
        ];
        return (React.createElement(page_1.Page, { seamless: false, sections: sections }));
    };
    Payment.prototype.renderPaymentSection = function () {
        return (React.createElement(ap_react_2.FormIsland, { key: "payment-form-island" },
            React.createElement(ap.Vertical, null,
                React.createElement(ap.Vertical, null,
                    React.createElement(ap.HeadingWithHr, null,
                        React.createElement(ap_react_1.Heading4, null, "Order Summary")),
                    React.createElement(ap.FlexVerticalMargined, null,
                        React.createElement(ap.Horizontal, null,
                            React.createElement(ap.Content, null,
                                React.createElement(ap_react_1.Paragraph, null,
                                    React.createElement("span", { id: "totalDocumentsCount" }, sendState_1.sendState.priceCalculator.totalDocumentsCount),
                                    " x Secure document delivery")),
                            React.createElement(ap.Flex, null),
                            React.createElement(ap.Content, null,
                                React.createElement(ap_react_1.Paragraph, null,
                                    "$",
                                    React.createElement("span", { id: "totalCost" }, sendState_1.sendState.priceCalculator.price)))),
                        React.createElement(ap_react_3.Hr, null),
                        React.createElement(ap.FlexHorizontalMargined, null,
                            React.createElement(ap.Flex, null),
                            React.createElement(ap.Vertical, { spacing: 5 },
                                React.createElement(ap_react_1.Heading4, null, "Order Total"),
                                React.createElement(ap.Paragraph, null, "included GST")),
                            React.createElement(ap.Vertical, { spacing: 5 },
                                React.createElement(ap_react_1.Heading4, { id: "orderTotal" }, "$" + sendState_1.sendState.priceCalculator.price),
                                React.createElement(ap.Paragraph, null,
                                    "$",
                                    React.createElement("span", { id: "gst" }, sendState_1.sendState.priceCalculator.gst)))))),
                React.createElement(ap.Vertical, null,
                    React.createElement(ap.HeadingWithHr, null,
                        React.createElement(ap_react_1.Heading4, null, "Payment")),
                    React.createElement(ap.Paragraph, null, "Visa and Mastercard, credit and debit cards accepted"),
                    React.createElement(ap.Content, null,
                        React.createElement(payhive_1.Payhive, { apiKey: config_1.config.payhive.apiKey, scriptUrl: config_1.config.payhive.scriptUrl, allowedCardTypes: ['visa', 'mastercard'], tokenizing: function (tokenizing) {
                                sendState_1.sendState.creditCard.setTokenizing(tokenizing);
                            }, onFormValidityChange: function (valid) {
                                if (!valid) {
                                    sendState_1.sendState.creditCard.clearCreditCard();
                                }
                            }, onTokenizeSuccess: function (creditCard) {
                                sendState_1.sendState.creditCard.setCreditCard(creditCard);
                            }, onTokenizeError: function (errorMessage) {
                                sendState_1.sendState.creditCard.clearCreditCard(errorMessage);
                            }, onHorribleError: function () { return yester_1.navigate(routes_1.route.error()); } }))),
                !!sendState_1.sendState.creditCard.paymentFailureMessage &&
                    React.createElement(ap_react_6.Alert, { type: ap_react_6.AlertType.Error }, sendState_1.sendState.creditCard.paymentFailureMessage),
                React.createElement(ap.CheckboxField, { id: "termAndConditions", label: React.createElement("span", null,
                        "I agree to the ",
                        React.createElement(ap.Anchor, { href: routes_1.docs.termsAndConditions(), target: "_blank" }, "terms and conditions")), fieldState: sendState_1.sendState.termsAndConditions }),
                React.createElement(ap.Horizontal, { horizontalAlign: "right", verticalAlign: "center" },
                    sendState_1.sendState.creditCard.tokenizing && React.createElement(ap.Horizontal, { spacing: 10 },
                        React.createElement(ap_react_1.Paragraph, null, "Verifying credit card"),
                        React.createElement(ap.Loader, null)),
                    React.createElement(ap.Horizontal, { spacing: ap.Spacing.horizontalBetweenFields },
                        React.createElement(ap_react_4.PrimaryButton, { id: "payAndSend", loading: sendState_1.sendState.doingFinalSend, disabled: sendState_1.sendState.isPaymentDisabled, onClick: function () { return sendState_1.sendState.doFinalSend(); } }, "Pay and send"))))));
    };
    Payment = __decorate([
        ap_react_5.observer
    ], Payment);
    return Payment;
}(React.Component));
exports.Payment = Payment;
