"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var ap = __importStar(require("ap-react"));
var ap_react_1 = require("ap-react");
var React = __importStar(require("react"));
var receiveState_1 = require("../state/receiveState");
var ReceiveMfaPinInfoSection = /** @class */ (function (_super) {
    __extends(ReceiveMfaPinInfoSection, _super);
    function ReceiveMfaPinInfoSection(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    ReceiveMfaPinInfoSection.prototype.render = function () {
        var _this = this;
        return (React.createElement(React.Fragment, null,
            React.createElement(ap.Vertical, { spacing: 20 },
                React.createElement(ap_react_1.Paragraph, null, "To access your police check certificate you will need the verification code."),
                React.createElement(ap_react_1.Paragraph, null,
                    " ",
                    receiveState_1.receiveState.instructionPinInfoLabel,
                    " "),
                React.createElement(ap.ContentHorizontal, null,
                    React.createElement(ap_react_1.PrimaryButton, { id: 'getCodeBtn', width: "100%", onClick: function (e) {
                            e.stopPropagation();
                            receiveState_1.receiveState.sendPin(_this.props.params);
                        }, loading: receiveState_1.receiveState.sendingPin }, "Get code")),
                React.createElement(ap_react_1.SmallVerticalSpace, { space: 15 }),
                React.createElement(ap_react_1.Paragraph, null, "We are aware of an issue with SMS delivery. Please avoid resubmitting SMS requests whilst we resolve the problem. Thank you for your patience at this time."),
                React.createElement(ap_react_1.Paragraph, null, "If the mobile number is incorrect please contact 13POST (13 76 78)."))));
    };
    ReceiveMfaPinInfoSection = __decorate([
        ap_react_1.observer
    ], ReceiveMfaPinInfoSection);
    return ReceiveMfaPinInfoSection;
}(React.Component));
exports.ReceiveMfaPinInfoSection = ReceiveMfaPinInfoSection;
