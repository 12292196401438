"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var windowDebug = typeof window !== 'undefined' && window.location.href.includes('?debug');
function getEnvFromHost(config) {
    var host = typeof window !== 'undefined' && typeof window.location !== 'undefined'
        ? window.location.host
        : '';
    var keys = Object.keys(config);
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        if (config[key](host))
            return key;
    }
    throw new Error('Unknown environment');
}
var env = getEnvFromHost({
    'prod': function (hostname) { return hostname.startsWith('sdd.auspost'); },
    'test': function (hostname) { return hostname.startsWith('sdd.stest'); },
    // Fallback
    'dev': function () { return true; },
});
/**
 * `process.env.NODE_ENV` definition is driven from webpack
 *
 * The whole `else` block will be removed in the emitted JavaScript
 *  for a production build
 */
if (env === 'prod') {
    exports.config = {
        debug: false,
        websiteRoot: 'https://sdd.auspost.com.au/',
        mixpanelToken: '41bf594a18013612c31e7878c0897fa6',
        sd2Controller: {
            initialiaseTransaction: 'https://ea53a8vyi6.dont.know.yet.com/prod/sd2/initialise-transaction',
            initialiseTransfer: 'https://ea53a8vyi6.dont.know.yet.com/prod/sd2/initialise-transfer',
            processTransaction: 'https://ea53a8vyi6.dont.know.yet.com/prod/sd2/process-transaction',
            calculateDocumentPrice: 'https://ea53a8vyi6.dont.know.yet.com/prod/sd2/calculate-docs-price',
        },
        sd2DocumentManager: {
            uploadUrl: 'https://ea53a8vyi6.dont.know.yet.com/prod/doc-manager/upload-url',
            downloadUrl: 'https://ea53a8vyi6.dont.know.yet.com/prod/doc-manager/download-url',
        },
        landingPageUserDetails: {
            updateUrl: 'https://ea53a8vyi6.dont.know.yet.com/prod/landing-page',
        },
        payhive: {
            scriptUrl: 'https://payments.auspost.net.au/ui/client/payhive.js',
            apiKey: 'sd2ProdApiKey'
        },
        sd2MfaConfig: {
            getMfaInfoEndpoint: 'https://api.sdd.auspost.com.au/collection/api/collect/info',
            sendPinEndpoint: 'https://api.sdd.auspost.com.au/collection/api/collect/sendpin',
            checkPinEndpoint: 'https://api.sdd.auspost.com.au/collection/api/collect/verifypin',
        },
        sd2ShareDocument: {
            shareUrl: "https://api.sdd.auspost.com.au/collection/api/collect/share"
        }
    };
    console.log('Running in production');
}
else if (env === 'test') {
    exports.config = {
        debug: windowDebug,
        websiteRoot: 'http://ui-test-auspostsd2.s3-website-ap-southeast-2.amazonaws.com/',
        mixpanelToken: '9904255c79b1e4405dce7d9b9e65b609',
        sd2Controller: {
            initialiaseTransaction: 'https://147wzsly61.execute-api.ap-southeast-2.amazonaws.com/Prod/sd2/initialise-transaction',
            initialiseTransfer: 'https://147wzsly61.execute-api.ap-southeast-2.amazonaws.com/Prod/sd2/initialise-transfer',
            processTransaction: 'https://147wzsly61.execute-api.ap-southeast-2.amazonaws.com/Prod/sd2/process-transaction',
            calculateDocumentPrice: 'https://147wzsly61.execute-api.ap-southeast-2.amazonaws.com/Prod/sd2/calculate-docs-price',
        },
        sd2DocumentManager: {
            uploadUrl: 'https://0kpx3fasy1.execute-api.ap-southeast-2.amazonaws.com/api/upload-url',
            downloadUrl: 'https://h8amdgoxj2.dont.know.yet.com/test/doc-manager/download-url'
        },
        landingPageUserDetails: {
            updateUrl: 'https://h8amdgoxj2.dont.know.yet.com/test/landing-page',
        },
        payhive: {
            scriptUrl: 'https://payments-stest.npe.auspost.zone/ui/client/payhive.js',
            apiKey: 'sd2ApiKey'
        },
        sd2MfaConfig: {
            getMfaInfoEndpoint: 'https://api.sdd.stest.npe.auspost.com.au/collection/api/collect/info',
            sendPinEndpoint: 'https://api.sdd.stest.npe.auspost.com.au/collection/api/collect/sendpin',
            checkPinEndpoint: 'https://api.sdd.stest.npe.auspost.com.au/collection/api/collect/verifypin',
        },
        sd2ShareDocument: {
            shareUrl: "https://api.sdd.stest.npe.auspost.com.au/collection/api/collect/share"
        }
    };
    console.log('Running in test');
}
else {
    exports.config = {
        debug: true,
        websiteRoot: 'http://sdd.ptest.npe.auspost.com.au/',
        mixpanelToken: '7618126a832ac715d088617bafaa11b7',
        sd2Controller: {
            initialiaseTransaction: 'https://ghd977ki5a.execute-api.ap-southeast-2.amazonaws.com/api/initialise-transaction',
            initialiseTransfer: 'https://ghd977ki5a.execute-api.ap-southeast-2.amazonaws.com/api/initialise-transfer',
            processTransaction: 'https://ghd977ki5a.execute-api.ap-southeast-2.amazonaws.com/api/process-transaction',
            calculateDocumentPrice: 'https://ghd977ki5a.execute-api.ap-southeast-2.amazonaws.com/api/calculate-docs-price',
        },
        sd2DocumentManager: {
            uploadUrl: 'https://xht7ybbiv5.execute-api.ap-southeast-2.amazonaws.com/api/upload-url',
            downloadUrl: 'https://ghd977ki5a.execute-api.ap-southeast-2.amazonaws.com/Prod/doc-manager/download-url'
        },
        landingPageUserDetails: {
            updateUrl: 'https://ghd977ki5a.execute-api.ap-southeast-2.amazonaws.com/Prod/landing-page',
        },
        payhive: {
            scriptUrl: 'https://payments-stest.npe.auspost.zone/ui/client/payhive.js',
            apiKey: 'sd2ApiKey'
        },
        sd2MfaConfig: {
            getMfaInfoEndpoint: 'https://api.sdd.ptest.npe.auspost.com.au/collection/api/collect/info',
            sendPinEndpoint: 'https://api.sdd.ptest.npe.auspost.com.au/collection/api/collect/sendpin',
            checkPinEndpoint: 'https://api.sdd.ptest.npe.auspost.com.au/collection/api/collect/verifypin',
        },
        sd2ShareDocument: {
            shareUrl: "https://api.sdd.ptest.npe.auspost.com.au/collection/api/collect/share"
        }
    };
    console.log('Running in dev');
}
