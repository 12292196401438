"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var routes_1 = require("./routes");
var landing_1 = require("../pages/landing");
var send_1 = require("../pages/send");
var payment_1 = require("../pages/payment");
var success_1 = require("../pages/success");
var error_1 = require("../pages/error");
var registerInterest_1 = require("../pages/registerInterest");
var receiveMfa_1 = require("../pages/receiveMfa");
var receiveDocs_1 = require("../pages/receiveDocs");
var sendState_1 = require("../state/sendState");
var receiveState_1 = require("../state/receiveState");
var alertOnLeave_1 = require("../utils/alertOnLeave");
var config_1 = require("../config/config");
var yester_1 = require("yester");
var ap = __importStar(require("ap-react"));
var ap_react_1 = require("ap-react");
var mobx_1 = require("mobx");
var metricsService_1 = require("../metrics/metricsService");
var util_1 = require("../services/util");
/**
 * Route state
 */
var RouteState = /** @class */ (function () {
    function RouteState() {
        this.currentRoute = 'landing';
        /** Used to prevent user naving away from send */
        this.inSendWorkflow = false;
    }
    __decorate([
        mobx_1.observable
    ], RouteState.prototype, "currentRoute", void 0);
    __decorate([
        mobx_1.observable
    ], RouteState.prototype, "inSendWorkflow", void 0);
    __decorate([
        mobx_1.observable
    ], RouteState.prototype, "currentParams", void 0);
    return RouteState;
}());
var routeState = new RouteState();
/**
 * Routes component
 */
exports.Routes = ap_react_1.observer(function () {
    switch (routeState.currentRoute) {
        case 'landing':
            return React.createElement(landing_1.Landing, null);
        case 'registerInterest':
            return React.createElement(registerInterest_1.RegisterInterest, null);
        /** send */
        case 'send':
            return React.createElement(send_1.Send, null);
        case 'payment':
            return React.createElement(payment_1.Payment, null);
        case 'success':
            return React.createElement(success_1.SuccessSummary, null);
        case 'surveyComplete':
            return (React.createElement(ap.MaxWidthIsland, null,
                React.createElement(ap.Heading4, null, "Successfully submitted. Thanks for your feedback!")));
        /** receive */
        case 'receiveMfa':
            return React.createElement(receiveMfa_1.ReceiveMfaPage, { params: routeState.currentParams });
        case 'receiveDocs':
            return React.createElement(receiveDocs_1.ReceiveDocsPage, { params: routeState.currentParams });
        case 'error':
            return React.createElement(error_1.ErrorPage, null);
        default:
            var _ = routeState.currentRoute;
    }
});
/**
 * Alert on browser close
 */
var leaveMessage = 'Are you sure you want to navigate away? All unsaved data will be lost';
alertOnLeave_1.alertOnLeave(function () {
    if (config_1.config.debug) {
        return null;
    }
    if (routeState.inSendWorkflow) {
        return leaveMessage;
    }
});
/**
 * Maps urls to route state + uses it to prevent navigation etc.
 */
var router = new yester_1.Router([
    /** Register interest */
    {
        $: routes_1.route.registerInterest(),
        beforeEnter: function () {
            // Redirect to landing page
            return { redirect: routes_1.route.landing() };
        },
        enter: function () {
            routeState.currentRoute = 'registerInterest';
        }
    },
    /** Send workflow */
    {
        $: routes_1.route.sendNew(),
        beforeEnter: function () {
            // Redirect to landing page
            return { redirect: routes_1.route.landing() };
        },
        enter: function () {
            sendState_1.sendState.reset();
            routeState.currentRoute = 'send';
            routeState.inSendWorkflow = true;
        },
        beforeLeave: function (_a) {
            var newPath = _a.newPath;
            if (newPath === routes_1.route.error()) {
                return;
            }
            if (routeState.inSendWorkflow && newPath !== routes_1.route.payment()) {
                return confirm(leaveMessage);
            }
        }
    },
    {
        $: routes_1.route.payment(),
        beforeEnter: function () {
            var locationForInvalidData = { redirect: routes_1.route.sendNew() };
            if (config_1.config.debug) {
                return null;
            }
            if (sendState_1.sendState.transfers.length < 0) {
                return locationForInvalidData;
            }
            if (!sendState_1.sendState.transfers[0].files.$.length) {
                return locationForInvalidData;
            }
        },
        enter: function () {
            routeState.currentRoute = 'payment';
        },
        beforeLeave: function (_a) {
            var newPath = _a.newPath;
            if (newPath === routes_1.route.error()) {
                return;
            }
            if (routeState.inSendWorkflow && newPath !== routes_1.route.success()) {
                return confirm(leaveMessage);
            }
        }
    },
    {
        $: routes_1.route.success(),
        beforeEnter: function () {
            var locationForInvalidData = { redirect: routes_1.route.sendNew() };
            if (config_1.config.debug) {
                return null;
            }
            if (sendState_1.sendState.transfers.length < 0) {
                return locationForInvalidData;
            }
            if (!sendState_1.sendState.transfers[0].files.$.length) {
                return locationForInvalidData;
            }
        },
        enter: function () {
            routeState.currentRoute = 'success';
            routeState.inSendWorkflow = false;
        },
    },
    {
        $: routes_1.route.surveyComplete(),
        enter: function () {
            routeState.currentRoute = 'surveyComplete';
        },
    },
    /** Receive workflow */
    {
        $: routes_1.route.receiveMfa(':transactionId', ':transferId'),
        enter: function (_a) {
            var params = _a.params;
            receiveState_1.receiveState.reset();
            var cleanedParams = cleanUpStringFromParams(params, "transferId");
            routeState.currentParams = cleanedParams;
            routeState.currentRoute = 'receiveMfa';
            receiveState_1.receiveState.getInfo(cleanedParams);
            metricsService_1.mixpanel.identify(metricsService_1.buildIdentityId(cleanedParams.transactionId, (cleanedParams.transferId)));
            metricsService_1.mixpanel.track({ type: 'page-viewed', data: { pageName: "receiveMfa" } });
        },
    },
    {
        $: routes_1.route.receiveDocs(':transactionId', ':transferId'),
        beforeEnter: function (_a) {
            var params = _a.params;
            var cleanedParams = cleanUpStringFromParams(params, "transferId");
            var transactionId = cleanedParams.transactionId, transferId = cleanedParams.transferId;
            if (!receiveState_1.receiveState.transfer.transfer) {
                return { redirect: routes_1.route.receiveMfa(transactionId, transferId), replace: true };
            }
        },
        enter: function (_a) {
            var params = _a.params;
            var cleanedParams = cleanUpStringFromParams(params, "transferId");
            routeState.currentParams = cleanedParams;
            routeState.currentRoute = 'receiveDocs';
            metricsService_1.mixpanel.identify(metricsService_1.buildIdentityId(cleanedParams.transactionId, cleanedParams.transferId));
            metricsService_1.mixpanel.track({ type: 'page-viewed', data: { pageName: "receiveDocs" } });
        },
    },
    /** Default */
    {
        $: routes_1.route.error(),
        enter: function () {
            routeState.currentRoute = 'error';
        }
    },
    {
        $: routes_1.route.landing(),
        enter: function () {
            routeState.currentRoute = 'landing';
        }
    },
    {
        $: '*',
        enter: function () {
            routeState.currentRoute = 'landing';
            metricsService_1.mixpanel.track({ type: 'page-viewed', data: { pageName: "landing" } });
        }
    }
]);
var cleanUpStringFromParams = function (o, keyToRemove) {
    var clonedObject = util_1.Util.deepClone(o);
    var paramValue = clonedObject[keyToRemove];
    paramValue = removeQueryString(paramValue);
    paramValue = paramValue.replace(" --use-spdy=off --disable-http2", "");
    clonedObject[keyToRemove] = paramValue;
    return clonedObject;
};
var removeQueryString = function (s) {
    var valueAndQSSplit = s.split("?");
    var value = valueAndQSSplit[0];
    return value;
};
router.init();
