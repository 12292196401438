"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var ap_react_1 = require("ap-react");
var validators_1 = require("./validators");
var shareDocumentService = __importStar(require("../services/shareDocumentService"));
var routes_1 = require("../routing/routes");
var yester_1 = require("yester");
var ShareDocumentState = /** @class */ (function () {
    function ShareDocumentState() {
        this.committingShare = false;
        this.recipientFirstName = new ap_react_1.FieldState('').validators(ap_react_1.validation.required);
        this.recipientLastName = new ap_react_1.FieldState('').validators(ap_react_1.validation.required);
        this.recipientEmail = new ap_react_1.FieldState('').validators(ap_react_1.validation.required, ap_react_1.validation.email);
        this.recipientMobileNumber = new ap_react_1.FieldState('').validators(ap_react_1.validation.required, validators_1.phoneNumberValidator);
        this.formState = new ap_react_1.FormState({
            recipientFirstName: this.recipientFirstName,
            recipientLastName: this.recipientLastName,
            recipientEmail: this.recipientEmail,
            recipientMobileNumber: this.recipientMobileNumber
        });
        this.reset();
    }
    ShareDocumentState.prototype.shareDocument = function (_a) {
        var nonce = _a.nonce, recipientFirstName = _a.recipientFirstName, recipientLastName = _a.recipientLastName, recipientEmail = _a.recipientEmail, recipientMobileNumber = _a.recipientMobileNumber, transactionId = _a.transactionId, transferId = _a.transferId;
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log("Submit document share");
                        this.committingShare = true;
                        return [4 /*yield*/, shareDocumentService.shareDocument({
                                nonce: nonce,
                                recipientFirstName: recipientFirstName,
                                recipientLastName: recipientLastName,
                                recipientEmail: recipientEmail,
                                recipientMobileNumber: recipientMobileNumber,
                                transactionId: transactionId,
                                transferId: transferId
                            })];
                    case 1:
                        result = _b.sent();
                        this.committingShare = false;
                        console.log("Submit result: " + result.message);
                        if (result.message === "SUCCESS") {
                            return [2 /*return*/];
                        }
                        else {
                            yester_1.navigate(routes_1.route.error());
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ShareDocumentState.prototype.reset = function () {
        console.log("reset fields to empty");
        this.committingShare = false;
        this.recipientFirstName.reset();
        this.recipientLastName.reset();
        this.recipientEmail.reset();
        this.recipientMobileNumber.reset();
        this.formState.reset();
    };
    __decorate([
        mobx_1.observable
    ], ShareDocumentState.prototype, "committingShare", void 0);
    __decorate([
        mobx_1.observable
    ], ShareDocumentState.prototype, "recipientFirstName", void 0);
    __decorate([
        mobx_1.observable
    ], ShareDocumentState.prototype, "recipientLastName", void 0);
    __decorate([
        mobx_1.observable
    ], ShareDocumentState.prototype, "recipientEmail", void 0);
    __decorate([
        mobx_1.observable
    ], ShareDocumentState.prototype, "recipientMobileNumber", void 0);
    __decorate([
        mobx_1.observable
    ], ShareDocumentState.prototype, "formState", void 0);
    __decorate([
        mobx_1.action
    ], ShareDocumentState.prototype, "shareDocument", null);
    __decorate([
        mobx_1.action
    ], ShareDocumentState.prototype, "reset", null);
    return ShareDocumentState;
}());
exports.ShareDocumentState = ShareDocumentState;
exports.shareDocumentState = new ShareDocumentState();
