"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var ap_react_1 = require("ap-react");
var config_1 = require("../config/config");
var ap = __importStar(require("ap-react"));
exports.buildIdentityId = function (transactionId, transferId) {
    var identityId;
    if (transactionId === transferId) {
        identityId = transferId;
    }
    else {
        identityId = transactionId + " | " + transferId;
    }
    return identityId;
};
/** Mixpanel object using the project token from your project settings */
exports.mixpanel = new ap_react_1.MixpanelService(config_1.config.mixpanelToken);
var tempId = ap.utils.createGuid();
exports.mixpanel.identify(tempId);
