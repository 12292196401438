"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var csstips = __importStar(require("csstips"));
var typestyle = __importStar(require("typestyle"));
var ap = __importStar(require("ap-react"));
var ap_react_1 = require("ap-react");
var formstate_1 = require("formstate");
var DropzoneStyles;
(function (DropzoneStyles) {
    var dropzoneTextColor = ap.Colors.warmGrey4;
    DropzoneStyles.rootClassName = typestyle.style(csstips.content, csstips.flexRoot, csstips.flex, {
        position: 'relative'
    });
    DropzoneStyles.buttonClassName = typestyle.style(csstips.padding(24), csstips.vertical, csstips.centerCenter, {
        width: '100%',
        backgroundColor: ap.Colors.white,
        color: dropzoneTextColor,
        borderStyle: 'dashed',
        borderColor: ap.Colors.warmGrey3,
        borderWidth: '2px',
        cursor: 'pointer',
    });
    DropzoneStyles.input = {
        /**
         * Due to testing sadness cannot use display:none
         * Also cannot use 0height or 0opacity (we tried)
         * So we have a super small height with super low opacity input
         **/
        height: '1px',
        overflow: 'hidden',
        opacity: 0.000001
    };
})(DropzoneStyles = exports.DropzoneStyles || (exports.DropzoneStyles = {}));
/**
 * - Holds a file
 * - Allows you to customize the UI (place holder)
 */
var SingleFileDropzone = /** @class */ (function (_super) {
    __extends(SingleFileDropzone, _super);
    function SingleFileDropzone(props) {
        var _this = _super.call(this, props) || this;
        // http://stackoverflow.com/a/6756680/390330
        _this.preventDefault = function (e) {
            e = e || event;
            e.preventDefault();
        };
        _this.ctrls = {};
        _this.handleChange = function (evt) {
            var fileInput = _this.ctrls.input;
            var event = evt.nativeEvent;
            // Ignore the no file selected case
            if (!fileInput.value) {
                return;
            }
            // Copy the files to remove a direct link to FileList
            // Needed otherwise it becomes an empty array after we clear the input
            var files = Array.from(event.target.files);
            // Add to queue
            _this.addFilesToUploadQueue(files);
            // clear the input
            fileInput.value = "";
        };
        _this.addFilesToUploadQueue = function (files) {
            /** Only support single files at the moment */
            var file = files[0];
            formstate_1.applyValidators(file, _this.props.validators || []).then(function (rejectReason) {
                if (rejectReason) {
                    _this.setState({ rejectReason: rejectReason });
                    return;
                }
                else {
                    _this.setState({ rejectReason: undefined });
                }
                _this.props.onFileAdded(file);
            });
        };
        /**
         * Drag and drop support
         */
        _this.onDragLeave = function () {
            _this.setState({
                isDragActive: false,
            });
        };
        _this.onDragOver = function (e) {
            e.preventDefault();
            _this.setState({
                isDragActive: true,
            });
        };
        _this.onDrop = function (e) {
            e.preventDefault();
            _this.setState({
                isDragActive: false,
            });
            var files;
            if (e.dataTransfer) {
                files = e.dataTransfer.files;
            }
            else if (e.target) {
                files = e.target.files;
            }
            files = Array.prototype.slice.call(files);
            _this.addFilesToUploadQueue(files);
        };
        _this.state = {};
        return _this;
    }
    SingleFileDropzone.prototype.componentDidMount = function () {
        /**
         * While some dropzone is on the page if a drop makes it to the `body` prevent default
         * Otherwise the page will reload and user will lose all state
         */
        window.addEventListener('dragover', this.preventDefault, false);
        window.addEventListener('drop', this.preventDefault, false);
    };
    SingleFileDropzone.prototype.componentWillUnmount = function () {
        window.removeEventListener('dragover', this.preventDefault, false);
        window.removeEventListener('drop', this.preventDefault, false);
    };
    SingleFileDropzone.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { ref: function (root) { return _this.ctrls.root = root; }, className: DropzoneStyles.rootClassName },
            React.createElement("div", { className: DropzoneStyles.buttonClassName, onClick: function (evt) { return _this.ctrls.input.click(); }, onDragLeave: this.onDragLeave, onDragOver: this.onDragOver, onDrop: this.onDrop },
                React.createElement("input", { id: this.props.id, ref: function (input) { return _this.ctrls.input = input; }, type: "file", style: DropzoneStyles.input, 
                    /** Disable multiple for now */
                    multiple: false, onChange: this.handleChange }),
                this.props.contents,
                !!this.state.rejectReason
                    && React.createElement("div", { className: ap_react_1.FieldStyles.errorTextClassName, style: { paddingTop: '10px' } }, this.state.rejectReason))));
    };
    return SingleFileDropzone;
}(React.Component));
exports.SingleFileDropzone = SingleFileDropzone;
/** Uploads a file to the server */
function uploadFile(config) {
    var xhr = new XMLHttpRequest();
    /** useful to store to prevent any onProgress after failure */
    var failed = false;
    // progress bar
    xhr.upload.addEventListener("progress", function (e) {
        if (failed)
            return;
        var pc = parseInt((e.loaded / e.total * 100).toString());
        config.onProgress(pc);
    }, false);
    // file received / failed
    xhr.onreadystatechange = function (e) {
        if (xhr.readyState == 4) {
            if (xhr.status == 200) {
                config.onSuccess();
            }
            else {
                failed = true;
                config.onFail();
            }
        }
        ;
    };
    // NOTE: this is our `doc-manager` specific stuff
    xhr.open("PUT", config.url, true); // We only use put
    Object.keys(config.headers).forEach(function (key) {
        xhr.setRequestHeader(key, config.headers[key]);
    });
    // start upload
    xhr.send(config.file);
}
exports.uploadFile = uploadFile;
