"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var page_1 = require("./base/page");
var ap_react_1 = require("ap-react");
var ap_react_2 = require("ap-react");
var ap_react_3 = require("ap-react");
var sendState_1 = require("../state/sendState");
var ap_react_4 = require("ap-react");
var appStepper_1 = require("./components/appStepper");
var routes_1 = require("../routing/routes");
var ap_react_5 = require("ap-react");
var formatters_1 = require("../utils/formatters");
var ap = __importStar(require("ap-react"));
var formatters_2 = require("../utils/formatters");
var yester_1 = require("yester");
var survey_1 = require("./components/survey");
var SuccessSummary = /** @class */ (function (_super) {
    __extends(SuccessSummary, _super);
    function SuccessSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SuccessSummary.prototype.render = function () {
        var sections = [
            React.createElement(ap_react_2.MaxWidthIsland, { key: "success-maxwidth-island" },
                React.createElement(appStepper_1.AppStepper, { current: appStepper_1.AppProcessStep.Summary })),
            this.renderSuccessSection(),
            this.renderAddMoreSection(),
            this.renderSurvey(),
        ];
        return (React.createElement(page_1.Page, { seamless: false, sections: sections }));
    };
    SuccessSummary.prototype.renderSuccessSection = function () {
        var _this = this;
        return (React.createElement(ap.ListIsland, { key: "success-form-island" },
            React.createElement(ap.ListIsland_Form, null,
                React.createElement(ap.Vertical, null,
                    React.createElement(ap.Vertical, { horizontalAlign: "center" },
                        React.createElement(ap_react_5.Done, { size: 64 }),
                        React.createElement(ap.Paragraph, null, "Thank you, your documents have been sent.")),
                    React.createElement(ap.Vertical, null,
                        React.createElement(ap.HeadingWithHr, null,
                            React.createElement(ap_react_1.Heading4, null, "Receipt")),
                        React.createElement(ap.Horizontal, null,
                            React.createElement(ap_react_1.Paragraph, null, "Date paid"),
                            React.createElement(ap.Flex, null),
                            React.createElement(ap_react_1.Paragraph, { id: "datePaid" }, formatters_1.Dates.formatDatePaid(new Date()))),
                        React.createElement(ap.Horizontal, null,
                            React.createElement(ap_react_1.Paragraph, null, "Paid with"),
                            React.createElement(ap.Flex, null),
                            React.createElement(ap_react_1.Paragraph, null,
                                formatters_1.upperCaseFirstLetter(sendState_1.sendState.creditCard.payhiveCreditCard.scheme),
                                " ending with ",
                                sendState_1.sendState.creditCard.payhiveCreditCard.last4)),
                        React.createElement(ap.Horizontal, null,
                            React.createElement(ap_react_1.Paragraph, null,
                                React.createElement("span", { id: "totalDocumentsCount" }, sendState_1.sendState.priceCalculator.totalDocumentsCount),
                                " x Secure document delivery"),
                            React.createElement(ap.Flex, null),
                            React.createElement(ap_react_1.Paragraph, { id: "totalCost" },
                                "$",
                                sendState_1.sendState.priceCalculator.price)),
                        React.createElement(ap.SmallText, null, "You will also receive a receipt via email."),
                        React.createElement(ap_react_3.Hr, null),
                        React.createElement(ap.Horizontal, { horizontalAlign: "right" },
                            React.createElement(ap.Vertical, { spacing: 5 },
                                React.createElement(ap_react_1.Heading4, null, "Order Total"),
                                React.createElement(ap.Paragraph, null, "included GST")),
                            React.createElement(ap.Vertical, { spacing: 5 },
                                React.createElement(ap_react_1.Heading4, null, "$" + sendState_1.sendState.priceCalculator.price),
                                React.createElement(ap.Paragraph, null,
                                    "$",
                                    sendState_1.sendState.priceCalculator.gst)))))),
            React.createElement(ap.ListIsland_Header, null,
                React.createElement(ap.ListIsland_Header_Heading, null, "Your send summary"),
                React.createElement(ap.Flex, null),
                React.createElement(ap.ListIsland_Header_Heading, null, ap.moment().format('dddd Do MMMM YYYY, hh:mm a'))),
            sendState_1.sendState.transfers.map(function (transfer, key) { return _this.renderTransferSummary(transfer, key); })));
    };
    SuccessSummary.prototype.renderTransferSummary = function (transfer, key) {
        var marginForSummary = 10;
        return React.createElement(ap.ListIsland_Item, { key: key },
            React.createElement(ap.FlexHorizontalMargined, null,
                React.createElement("div", null,
                    React.createElement(ap.Mail, null)),
                React.createElement(ap.FlexVerticalMargined, { margin: marginForSummary },
                    React.createElement(ap.Horizontal, null,
                        React.createElement(ap.FlexVertical, null,
                            React.createElement(ap.Content, null,
                                React.createElement(ap.Heading5, null,
                                    "To: ",
                                    formatters_2.renderName({
                                        title: transfer.receiver.title.value.value,
                                        firstName: transfer.receiver.firstName.value,
                                        lastName: transfer.receiver.lastName.value,
                                    })),
                                React.createElement(ap.SmallVerticalSpace, { space: 10 })),
                            React.createElement(ap.ContentHorizontal, null,
                                React.createElement(ap.ContentVertical, null,
                                    React.createElement(ap_react_1.Paragraph, null, transfer.receiver.email.value),
                                    React.createElement(ap_react_1.Paragraph, null, transfer.receiver.phone.value)),
                                React.createElement(ap.Flex, null),
                                React.createElement(ap.Paragraph, null,
                                    transfer.files.value.length,
                                    " x Document",
                                    formatters_2.s(transfer.files.value.length))))),
                    React.createElement(ap_react_3.Hr, null),
                    !!transfer.receiver.customMessage.value.trim() && React.createElement(ap.Vertical, { spacing: marginForSummary },
                        React.createElement(ap.Content, null,
                            React.createElement(ap_react_1.Paragraph, { id: "customMessage" + key }, transfer.receiver.customMessage.value.trim())),
                        React.createElement(ap_react_3.Hr, null)),
                    React.createElement(ap.Content, null,
                        React.createElement(ap_react_1.Paragraph, null,
                            "From: ",
                            formatters_2.renderName({
                                title: transfer.sender.title.value.value,
                                firstName: transfer.sender.firstName.value,
                                lastName: transfer.sender.lastName.value,
                            }))))));
    };
    SuccessSummary.prototype.renderAddMoreSection = function () {
        /** Pay and Send */
        return (React.createElement(ap.MaxWidthIsland, null,
            React.createElement(ap.Vertical, { spacing: 15, horizontalAlign: "center" },
                React.createElement(ap_react_1.Heading4, null, "Thanks for sending with us"),
                React.createElement(ap_react_4.SecondaryButton, { id: "sendMore", onClick: function () { return yester_1.navigate(routes_1.route.sendNew()); } }, "Send more"))));
    };
    SuccessSummary.prototype.renderSurvey = function () {
        return (React.createElement(survey_1.SurveyMonkey, { height: 800, weblink: "https://www.surveymonkey.com/r/MPNW88V" }));
    };
    return SuccessSummary;
}(React.Component));
exports.SuccessSummary = SuccessSummary;
