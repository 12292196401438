"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var ap_react_1 = require("ap-react");
var AppProcessStep;
(function (AppProcessStep) {
    AppProcessStep[AppProcessStep["Send"] = 0] = "Send";
    AppProcessStep[AppProcessStep["Payment"] = 1] = "Payment";
    AppProcessStep[AppProcessStep["Summary"] = 2] = "Summary";
})(AppProcessStep = exports.AppProcessStep || (exports.AppProcessStep = {}));
exports.AppStepper = function (props) {
    return React.createElement(ap_react_1.Stepper, { current: props.current, steps: [
            {
                text: "Setup"
            },
            {
                text: "Pay and send"
            },
            {
                text: "Summary"
            }
        ] });
};
