"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Util = /** @class */ (function () {
    function Util() {
    }
    Util.deepClone = function (obj) {
        return JSON.parse(JSON.stringify(obj));
    };
    return Util;
}());
exports.Util = Util;
