"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
var ap_react_1 = require("ap-react");
var ap_react_2 = require("ap-react");
var page_1 = require("./base/page");
var appIcons_1 = require("../icons/appIcons");
var gls = __importStar(require("ap-react"));
var metricsService_1 = require("../metrics/metricsService");
var ErrorPage = /** @class */ (function (_super) {
    __extends(ErrorPage, _super);
    function ErrorPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ErrorPage.prototype.componentDidMount = function () {
        metricsService_1.mixpanel.track({ type: 'error', data: {} });
    };
    ErrorPage.prototype.render = function () {
        return (React.createElement(page_1.Page, { seamless: false, sections: [
                React.createElement(ap_react_2.MaxWidthReef, null,
                    React.createElement(gls.ContentVerticalCentered, null,
                        React.createElement(ap_react_1.Heading1, null, "Something unexpected happened"))),
                React.createElement(ap_react_2.MaxWidthIsland, null,
                    React.createElement(gls.ContentVerticalCentered, null,
                        React.createElement(appIcons_1.OutageIcon, null),
                        React.createElement(gls.SmallVerticalSpace, { space: 24 }),
                        React.createElement(ap_react_1.Heading4, null, "The site is currently unavailable. Please try again later.")))
            ] }));
    };
    return ErrorPage;
}(React.Component));
exports.ErrorPage = ErrorPage;
