"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var ap_react_1 = require("ap-react");
var React = __importStar(require("react"));
var yester_1 = require("yester");
var routes_1 = require("../../routing/routes");
/**
 * For Layout with
 * - Header up top
 * - Body sections that takes up the remaining space
 * - Footer on bottom
 */
exports.Page = function (props) {
    var useLandingPageTAndCs = props.useLandingPageTAndCs;
    var tAndCs = useLandingPageTAndCs ? routes_1.docs.landingPageTermsAndConditions() : routes_1.docs.termsAndConditions();
    return (React.createElement(ap_react_1.PageRoot, null,
        React.createElement(ap_react_1.Header, { headerLink: yester_1.link(routes_1.route.landing()), 
            /** Project name needs to be kept in sync with the page title in index.html */
            projectName: "Secure Document Delivery" }),
        React.createElement(ap_react_1.PageBody, { sections: props.sections, seamless: props.seamless }),
        React.createElement(ap_react_1.Footer, { links: [
                { href: tAndCs, text: 'Terms & Conditions', target: '_blank' },
                { href: routes_1.docs.privacyPolicy(), text: 'Privacy Policy', target: '_blank' },
            ] })));
};
