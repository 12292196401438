"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COMMON_ERROR = {
    DOC_EXPIRED: {
        name: "DOC_EXPIRED",
        message: "Documents have expired"
    },
    INVALID_PHONE_NUMBER: {
        name: "INVALID_PHONE_NUMBER",
        message: "Phone number provided is not valid. Please contact the organisation that has sent this delivery."
    }
};
