"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * @module anything to do with payhive api goes here
 */
var React = __importStar(require("react"));
var typestyle_1 = require("typestyle");
/**
 * a thin react wrapper around the payhive component
 */
var Payhive = /** @class */ (function (_super) {
    __extends(Payhive, _super);
    function Payhive() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Payhive.prototype.componentDidMount = function () {
        var _this = this;
        var payHiveScriptId = 'payhive-js';
        loadScriptOnce(this.props.scriptUrl, payHiveScriptId)
            .then(function () {
            if (!payhive) {
                _this.props.onHorribleError();
                return;
            }
            payhive.ui({
                containerId: Payhive.iframeContainerId,
                scriptId: payHiveScriptId,
                apiKey: _this.props.apiKey,
                showCardTypes: true,
                allowCardTypes: _this.props.allowedCardTypes,
                onFormValidityChange: function (valid) {
                    // console.log({ valid }); // DEBUG
                    if (valid) {
                        _this.props.tokenizing(true);
                        payhive.tokenize();
                    }
                    _this.props.onFormValidityChange(valid);
                },
                onTokenizeSuccess: function (creditCard) {
                    _this.props.tokenizing(false);
                    _this.props.onTokenizeSuccess(creditCard);
                },
                onTokenizeError: function (response) {
                    _this.props.tokenizing(false);
                    console.error('Payhive Error: ' + JSON.stringify(response));
                    _this.props.onTokenizeError("Failed to validate the credit card details. Please verify the information provided");
                }
            });
        });
    };
    Payhive.prototype.render = function () {
        return React.createElement("div", { id: Payhive.iframeContainerId });
    };
    Payhive.iframeContainerId = 'payhive-ui-container';
    /** Fix the layout for payhive iframe */
    Payhive.loadCSS = typestyle_1.cssRaw("\n#" + Payhive.iframeContainerId + " iframe {\n  min-height: 180px;\n  border: none;\n  width: 100%;\n}\n");
    return Payhive;
}(React.Component));
exports.Payhive = Payhive;
/**
 * Only loads a given scriptUrl once on demand
 */
var loadScriptOnce = (new /** @class */ (function () {
    function class_1() {
        var _this = this;
        this.alreadyLoaded = Object.create(null);
        this.loadScriptOnce = function (scriptUrl, scriptId) {
            if (!_this.alreadyLoaded[scriptUrl]) {
                _this.alreadyLoaded[scriptUrl] = _this.include(scriptUrl, scriptId);
            }
            return _this.alreadyLoaded[scriptUrl];
        };
        /**
        * Utility : Resolves when a script has been loaded
        */
        this.include = function (url, id) {
            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;
                script.id = id;
                script.onload = function () {
                    resolve({ script: script });
                };
                document.getElementsByTagName('head')[0].appendChild(script);
            });
        };
    }
    return class_1;
}())).loadScriptOnce;
