"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = __importStar(require("react"));
/**
 * How to bring in from auspost brand guide:
 * - copy paste into the svg tag. Preserve width/height/viewBox
 * - Fix any warning react gives you at runtime
 * - Inline all `use` tags
 * - Make unique all the # references in `url()`s
 */
var VoiIcon = /** @class */ (function (_super) {
    __extends(VoiIcon, _super);
    function VoiIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    VoiIcon.prototype.render = function () {
        return (React.createElement("svg", { width: "75px", height: "75px", viewBox: "0 0 75 75" },
            React.createElement("g", { id: "Page-1", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                React.createElement("g", { id: "SPLITTER", transform: "translate(-177.000000, -169.000000)" },
                    React.createElement("g", { id: "Page-1", transform: "translate(177.000000, 169.000000)" },
                        React.createElement("path", { d: "M74.858662,72.2454545 C74.858662,73.4722028 73.8482746,74.4755245 72.6128873,74.4755245 L2.24598592,74.4755245 C1.01059859,74.4755245 0.000211267606,73.4722028 0.000211267606,72.2454545 L0.000211267606,2.3701049 C0.000211267606,1.14388112 1.01059859,0.140559441 2.24598592,0.140559441 L72.6128873,0.140559441 C73.8482746,0.140559441 74.858662,1.14388112 74.858662,2.3701049 L74.858662,72.2454545 Z", id: "Fill-1", fill: "#D0D5D8" }),
                        React.createElement("path", { d: "M40.929507,67.4332867 C40.929507,65.5137063 39.3624296,63.9570629 37.429331,63.9570629 C35.4962324,63.9570629 33.9291549,65.5137063 33.9291549,67.4332867 C33.9291549,69.3528671 35.4962324,70.908986 37.429331,70.908986 C39.3624296,70.908986 40.929507,69.3528671 40.929507,67.4332867", id: "Fill-4", fill: "#66727A" }),
                        React.createElement("path", { d: "M68.8141373,5.30166084 L6.04441901,5.30166084 C5.36572183,5.30166084 4.8106162,5.85655594 4.8106162,6.53575175 L4.8106162,58.7719406 C4.8106162,59.4511364 5.36572183,60.0076049 6.04441901,60.0076049 L68.8141373,60.0076049 C69.4928345,60.0076049 70.0479401,59.4532343 70.0479401,58.7740385 L70.0479401,6.53732517 C70.0479401,5.85865385 69.4928345,5.30166084 68.8141373,5.30166084", id: "Fill-6", fill: "#5E514D" }),
                        React.createElement("path", { d: "M64.2396127,10.0774825 C64.4963028,10.0774825 64.7075704,10.2862238 64.7075704,10.5416434 L64.715493,21.3988112 C64.7160211,21.6542308 64.5058099,21.8634965 64.2485915,21.864021 L53.3144366,21.8718881 C53.0577465,21.8718881 52.847007,21.6631469 52.847007,21.4077273 L52.8385563,10.5505594 C52.8380282,10.2951399 53.0487676,10.0858741 53.3059859,10.0858741 L64.2396127,10.0774825 Z", id: "Fill-8", fill: "#EB5B7D" }),
                        React.createElement("path", { d: "M70.5719366,70.971451 C69.0814437,72.2543182 66.9402465,72.2270455 65.7898944,70.9090385 L49.0759859,51.7478497 C47.926162,50.4303671 48.2023944,48.321451 49.6934155,47.0385839 C51.1844366,45.7557168 53.3256338,45.783514 54.4749296,47.100472 L71.1893662,66.2621853 C72.3397183,67.5796678 72.0629577,69.6880594 70.5719366,70.971451", id: "Fill-10", fill: "#FFD501" }),
                        React.createElement("path", { d: "M54.5711092,14.145 C56.217412,14.3469231 57.5652993,15.860035 57.5668838,17.5074126 L57.5843134,38.2976224 C57.5858979,39.945 56.230088,41.2934266 54.5711092,41.2944755 L34.4647711,41.3096853 C32.8057923,41.3107343 31.4478697,39.9644056 31.4462852,38.317028 L31.4288556,14.2976224 C31.4272711,12.6502448 32.7730458,11.4680769 34.4193486,11.6705245 L54.5711092,14.145 Z", id: "Fill-12", fill: "#EB5B7D" }),
                        React.createElement("polygon", { id: "Fill-14", fill: "#F7F4F2", points: "45.2626585 22.3166434 34.1293838 33.3715385 29.5380106 28.8117483 24.6883627 33.626958 34.1293838 43.001958 34.1637148 42.9678671 34.164243 42.9678671 50.1123063 27.1318531" }),
                        React.createElement("path", { d: "M24.7769894,20.0909266 C21.3977641,23.446521 19.5364965,27.9087587 19.5359683,32.6547378 C19.5364965,42.4519406 27.563081,50.4229196 37.4292782,50.4229196 C42.2086796,50.4229196 46.7023415,48.5746678 50.0815669,45.218549 C53.4618486,41.8624301 55.322588,37.4007168 55.322588,32.6547378 C55.322588,22.857535 47.2954754,14.8865559 37.4292782,14.8865559 C32.6498768,14.8865559 28.1562148,16.7348077 24.7769894,20.0909266 M55.4646655,50.563479 C50.6467077,55.3466958 44.2421303,57.9816608 37.4292782,57.9816608 C23.3657218,57.9816608 11.9245246,46.6199476 11.9239965,32.6547378 C11.9245246,25.889528 14.5769894,19.5297378 19.394419,14.7459965 C24.2118486,9.96225524 30.6169542,7.32833916 37.4292782,7.32781469 C51.4928345,7.32833916 62.9345599,18.689528 62.9345599,32.6547378 C62.9345599,39.4199476 60.2815669,45.7797378 55.4646655,50.563479", id: "Fill-16", fill: "#FFD501" }))))));
    };
    return VoiIcon;
}(React.Component));
exports.VoiIcon = VoiIcon;
var DocumentStoreIcon = /** @class */ (function (_super) {
    __extends(DocumentStoreIcon, _super);
    function DocumentStoreIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DocumentStoreIcon.prototype.render = function () {
        return (React.createElement("svg", { width: "66px", height: "73px", viewBox: "0 0 66 73" },
            React.createElement("g", { id: "Page-1", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                React.createElement("g", { id: "SPLITTER", transform: "translate(-527.000000, -170.000000)" },
                    React.createElement("g", { id: "Page-1", transform: "translate(527.000000, 170.000000)" },
                        React.createElement("g", { id: "Group-3", transform: "translate(0.000000, 6.580127)" },
                            React.createElement("mask", { id: "mask-2", fill: "white" },
                                React.createElement("polygon", { id: "path-1", points: "32.9734582 66.3479369 65.9467304 66.3479369 65.9467304 0.12522005 0.0001861167 0.12522005 0.0001861167 66.3479369" })),
                            React.createElement("g", { id: "Clip-2" }),
                            React.createElement("path", { d: "M65.9469165,64.3612367 C65.9469165,65.4541087 65.0568134,66.3479369 63.968496,66.3479369 L1.97860664,66.3479369 C0.890289235,66.3479369 0.0001861167,65.4541087 0.0001861167,64.3612367 L0.0001861167,2.11192024 C0.0001861167,1.01904824 0.890289235,0.12522005 1.97860664,0.12522005 L63.968496,0.12522005 C65.0568134,0.12522005 65.9469165,1.01904824 65.9469165,2.11192024 L65.9469165,64.3612367 Z", id: "Fill-1", fill: "#36B4A3", mask: "url(#mask-2)" })),
                        React.createElement("path", { d: "M55.4783174,70.9413634 C55.4783174,72.0342354 54.5882143,72.9280636 53.4998969,72.9280636 L5.35197183,72.9280636 C4.26411972,72.9280636 3.37355131,72.0342354 3.37355131,70.9413634 L3.37355131,22.6134327 C3.37355131,21.521028 4.26411972,20.6267325 5.35197183,20.6267325 L21.4036066,20.6267325 L30.011504,25.9228865 L53.4998969,25.9228865 C54.5882143,25.9228865 55.4783174,26.8167147 55.4783174,27.9095867 L55.4783174,70.9413634 Z", id: "Fill-4", fill: "#978988" }),
                        React.createElement("path", { d: "M52.1049522,70.9413634 C52.1049522,72.0342354 51.2148491,72.9280636 50.1265317,72.9280636 L1.97860664,72.9280636 C0.890754527,72.9280636 0.0001861167,72.0342354 0.0001861167,70.9413634 L0.0001861167,22.6134327 C0.0001861167,21.521028 0.890754527,20.6267325 1.97860664,20.6267325 L18.0302414,20.6267325 L26.6381388,25.9228865 L50.1265317,25.9228865 C51.2148491,25.9228865 52.1049522,26.8167147 52.1049522,27.9095867 L52.1049522,70.9413634 Z", id: "Fill-6", fill: "#E52629" }),
                        React.createElement("mask", { id: "mask-4", fill: "white" },
                            React.createElement("polygon", { id: "path-3", points: "0 72.9280636 65.9467304 72.9280636 65.9467304 0 0 0" })),
                        React.createElement("g", { id: "Clip-9" }),
                        React.createElement("polygon", { id: "Fill-8", fill: "#FEFEFE", mask: "url(#mask-4)", points: "4.67106388 53.4680268 47.4341675 53.4680268 47.4341675 0.000467238992 4.67106388 0.000467238992" }),
                        React.createElement("polygon", { id: "Fill-10", fill: "#D0D5D8", mask: "url(#mask-4)", points: "8.51251258 16.5323173 23.4116197 16.5323173 23.4116197 4.36915181 8.51251258 4.36915181" }),
                        React.createElement("polygon", { id: "Fill-11", fill: "#D0D5D8", mask: "url(#mask-4)", points: "25.5519618 6.92308014 43.6318033 6.92308014 43.6318033 4.32196068 25.5519618 4.32196068" }),
                        React.createElement("polygon", { id: "Fill-12", fill: "#D0D5D8", mask: "url(#mask-4)", points: "25.5519618 11.7155505 43.6318033 11.7155505 43.6318033 9.11443102 25.5519618 9.11443102" }),
                        React.createElement("polygon", { id: "Fill-13", fill: "#D0D5D8", mask: "url(#mask-4)", points: "25.5519618 16.5075536 43.6318033 16.5075536 43.6318033 13.9064341 25.5519618 13.9064341" }),
                        React.createElement("polygon", { id: "Fill-14", fill: "#D0D5D8", mask: "url(#mask-4)", points: "8.35198692 21.3000239 43.7527792 21.3000239 43.7527792 18.6989045 8.35198692 18.6989045" }),
                        React.createElement("polygon", { id: "Fill-15", fill: "#D0D5D8", mask: "url(#mask-4)", points: "8.35198692 26.0924943 43.7527792 26.0924943 43.7527792 23.4913748 8.35198692 23.4913748" }),
                        React.createElement("polygon", { id: "Fill-16", fill: "#D0D5D8", mask: "url(#mask-4)", points: "8.35198692 30.8849646 43.7527792 30.8849646 43.7527792 28.2838451 8.35198692 28.2838451" }),
                        React.createElement("g", { id: "Group-19", mask: "url(#mask-4)" },
                            React.createElement("g", { transform: "translate(0.000000, 32.278271)" },
                                React.createElement("mask", { id: "mask-6", fill: "white" },
                                    React.createElement("polygon", { id: "path-5", points: "52.1049522 0.0471911382 0.0001861167 0.0471911382 0.0001861167 38.1023586 52.1049522 38.1023586" })),
                                React.createElement("g", { id: "Clip-18", stroke: "none", fill: "none" }),
                                React.createElement("path", { d: "M52.1049522,36.1156584 C52.1049522,37.2085304 51.2148491,38.1023586 50.1265317,38.1023586 L1.97860664,38.1023586 C0.890754527,38.1023586 0.0001861167,37.2085304 0.0001861167,36.1156584 L0.0001861167,2.03384461 C0.0001861167,0.940972606 0.890754527,0.0471444143 1.97860664,0.0471444143 L50.1265317,0.0471444143 C51.2148491,0.0471444143 52.1049522,0.940972606 52.1049522,2.03384461 L52.1049522,36.1156584 Z", id: "Fill-17", stroke: "none", fill: "#F7F4F2", fillRule: "evenodd", mask: "url(#mask-6)" }))),
                        React.createElement("path", { d: "M52.1049522,70.9413634 C52.1049522,72.0342354 51.2148491,72.9280636 50.1265317,72.9280636 L1.97860664,72.9280636 C0.890754527,72.9280636 0.0001861167,72.0342354 0.0001861167,70.9413634 L0.0001861167,36.8595496 C0.0001861167,35.7666776 0.890754527,34.8728494 1.97860664,34.8728494 L50.1265317,34.8728494 C51.2148491,34.8728494 52.1049522,35.7666776 52.1049522,36.8595496 L52.1049522,70.9413634 Z", id: "Fill-20", fill: "#E52629", mask: "url(#mask-4)" }),
                        React.createElement("path", { d: "M59.6373277,60.8692793 C58.8346994,60.8692793 58.1851522,60.2165465 58.1851522,59.4110264 L58.1851522,49.085512 C58.1851522,45.2761125 55.0993373,42.1773835 51.3062789,42.1773835 C47.5127553,42.1773835 44.4274057,45.2761125 44.4274057,49.085512 L44.4274057,59.4110264 C44.4274057,60.2165465 43.7773931,60.8692793 42.9747648,60.8692793 C42.1735324,60.8692793 41.5230546,60.2165465 41.5230546,59.4110264 L41.5230546,49.085512 C41.5230546,43.6683431 45.9121517,39.2613449 51.3062789,39.2613449 C56.7004062,39.2613449 61.0895033,43.6683431 61.0895033,49.085512 L61.0895033,59.4110264 C61.0895033,60.2165465 60.4394907,60.8692793 59.6373277,60.8692793", id: "Fill-21", fill: "#89929C", mask: "url(#mask-4)" }),
                        React.createElement("path", { d: "M65.9469165,70.9413634 C65.9469165,72.0342354 65.0568134,72.9280636 63.968496,72.9280636 L38.6440619,72.9280636 C37.5552792,72.9280636 36.6656413,72.0342354 36.6656413,70.9413634 L36.6656413,53.3395361 C36.6656413,52.2471313 37.5552792,51.3528359 38.6440619,51.3528359 L63.968496,51.3528359 C65.0568134,51.3528359 65.9469165,52.2471313 65.9469165,53.3395361 L65.9469165,70.9413634 Z", id: "Fill-22", fill: "#66727A", mask: "url(#mask-4)" }),
                        React.createElement("path", { d: "M54.7244982,60.6589283 C54.7244982,62.5545169 53.1941537,64.091266 51.3059997,64.091266 C49.4187764,64.091266 47.8884318,62.5545169 47.8884318,60.6589283 C47.8884318,58.7628725 49.4187764,57.2261235 51.3059997,57.2261235 C53.1941537,57.2261235 54.7244982,58.7628725 54.7244982,60.6589283", id: "Fill-23", fill: "#291C23", mask: "url(#mask-4)" }),
                        React.createElement("polygon", { id: "Fill-24", fill: "#291C23", mask: "url(#mask-4)", points: "50.4287852 66.9871198 52.1834004 66.9871198 52.1834004 61.7021796 50.4287852 61.7021796" }))))));
    };
    return DocumentStoreIcon;
}(React.Component));
exports.DocumentStoreIcon = DocumentStoreIcon;
var DocumentSightingIcon = /** @class */ (function (_super) {
    __extends(DocumentSightingIcon, _super);
    function DocumentSightingIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DocumentSightingIcon.prototype.render = function () {
        return (React.createElement("svg", { width: "75px", height: "75px", viewBox: "0 0 75 75" },
            React.createElement("g", { id: "Page-1", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                React.createElement("g", { id: "SPLITTER", transform: "translate(-523.000000, -169.000000)" },
                    React.createElement("g", { id: "Page-1", transform: "translate(523.000000, 169.000000)" },
                        React.createElement("g", { id: "Group-3", transform: "translate(0.000000, 0.301817)" },
                            React.createElement("mask", { id: "mask-sighting-2", fill: "white" },
                                React.createElement("polygon", { id: "path-1", points: "74.7107877 74.3559521 74.7107877 0.208365949 0.000256849315 0.208365949 0.000256849315 74.3559521 74.7107877 74.3559521" })),
                            React.createElement("g", { id: "Clip-2" }),
                            React.createElement("path", { d: "M72.5930651,74.3559521 L2.1172089,74.3559521 C0.948544521,74.3559521 0.000256849315,73.414813 0.000256849315,72.2539389 L0.000256849315,2.30986935 C0.000256849315,1.1489952 0.948544521,0.208365949 2.1172089,0.208365949 L72.5930651,0.208365949 C73.7627568,0.208365949 74.7110445,1.1489952 74.7110445,2.30986935 L74.7110445,72.2539389 C74.7110445,73.414813 73.7627568,74.3559521 72.5930651,74.3559521", id: "Fill-1", fill: "#00C1AA", mask: "url(#mask-sighting-2)" })),
                        React.createElement("g", { id: "Group-6", transform: "translate(5.136986, 36.499474)" },
                            React.createElement("mask", { id: "mask-sighting-4", fill: "white" },
                                React.createElement("polygon", { id: "path-3", points: "0.352294521 38.2369615 38.8534932 38.2369615 38.8534932 0.0256952381 0.352294521 0.0256952381 0.352294521 38.2369615" })),
                            React.createElement("g", { id: "Clip-5" }),
                            React.createElement("path", { d: "M38.8534932,37.0920958 C38.8534932,37.7217311 38.3341438,38.2371654 37.699726,38.2371654 L1.50760274,38.2371654 C0.87369863,38.2371654 0.352294521,37.7217311 0.352294521,37.0920958 L0.352294521,1.17229426 C0.352294521,0.542149131 0.87369863,0.0256952381 1.50760274,0.0256952381 L37.699726,0.0256952381 C38.3341438,0.0256952381 38.8534932,0.542149131 38.8534932,1.17229426 L38.8534932,37.0920958 Z", id: "Fill-4", fill: "#CDC4C2", mask: "url(#mask-sighting-4)" })),
                        React.createElement("mask", { id: "mask-sighting-6", fill: "white" },
                            React.createElement("polygon", { id: "path-5", points: "0 74.7364354 74.7107877 74.7364354 74.7107877 0.509826153 0 0.509826153" })),
                        React.createElement("g", { id: "Clip-8" }),
                        React.createElement("polygon", { id: "Fill-7", fill: "#FEFEFE", mask: "url(#mask-sighting-6)", points: "38.6008562 54.8695299 41.2982877 54.8695299 41.2982877 51.1121111 38.6008562 51.1121111" }),
                        React.createElement("path", { d: "M38.6006507,73.5915698 C38.6006507,74.2212051 38.0813014,74.7366393 37.4458562,74.7366393 L1.25321918,74.7366393 C0.616746575,74.7366393 0.0979109589,74.2212051 0.0979109589,73.5915698 L0.0979109589,37.6717682 C0.0979109589,37.0416231 0.616746575,36.5251692 1.25321918,36.5251692 L37.4458562,36.5251692 C38.0813014,36.5251692 38.6006507,37.0416231 38.6006507,37.6717682 L38.6006507,73.5915698 Z", id: "Fill-9", fill: "#FFD859", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M34.9329966,44.951168 C34.9329966,45.470171 34.5055993,45.8933267 33.9847089,45.8933267 L25.1598801,45.8933267 C24.6379623,45.8933267 24.2100514,45.470171 24.2100514,44.951168 L24.2100514,42.8364091 C24.2100514,42.3194454 24.6379623,41.8947602 25.1598801,41.8947602 L33.9847089,41.8947602 C34.5055993,41.8947602 34.9329966,42.3194454 34.9329966,42.8364091 L34.9329966,44.951168 Z", id: "Fill-10", fill: "#291C23", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M0.0978082192,37.6719721 L0.0978082192,73.5917737 C0.0978082192,74.2208992 0.616643836,74.7363334 1.25311644,74.7363334 L37.4457534,74.7363334 C37.7632192,74.7363334 38.0524315,74.6073474 38.2604795,74.3998481 L0.43890411,36.8623682 C0.22880137,37.0698674 0.0978082192,37.3558799 0.0978082192,37.6719721", id: "Fill-11", fill: "#FFD501", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M26.7941096,68.7614258 C26.7941096,69.2804288 26.3667123,69.7040944 25.8447945,69.7040944 L4.71585616,69.7040944 C4.19342466,69.7040944 3.7660274,69.2804288 3.7660274,68.7614258 L3.7660274,66.9770343 C3.7660274,66.4580313 4.19342466,66.0338559 4.71585616,66.0338559 L25.8447945,66.0338559 C26.3667123,66.0338559 26.7941096,66.4580313 26.7941096,66.9770343 L26.7941096,68.7614258 Z", id: "Fill-12", fill: "#FEFEFE", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M26.7941096,63.3499781 C26.7941096,63.8684713 26.3667123,64.2926466 25.8447945,64.2926466 L4.71585616,64.2926466 C4.19342466,64.2926466 3.7660274,63.8684713 3.7660274,63.3499781 L3.7660274,61.567116 C3.7660274,61.048113 4.19342466,60.6239376 4.71585616,60.6239376 L25.8447945,60.6239376 C26.3667123,60.6239376 26.7941096,61.048113 26.7941096,61.567116 L26.7941096,63.3499781 Z", id: "Fill-13", fill: "#FEFEFE", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M51.369863,73.3964083 C51.369863,74.1331071 50.7616438,74.7362314 50.0193493,74.7362314 L7.66181507,74.7362314 C6.92054795,74.7362314 6.30976027,74.1331071 6.30976027,73.3964083 L6.30976027,31.3576732 C6.30976027,30.6199548 6.92054795,30.0163206 7.66181507,30.0163206 L50.0193493,30.0163206 C50.7616438,30.0163206 51.369863,30.6199548 51.369863,31.3576732 L51.369863,73.3964083 Z", id: "Fill-14", fill: "#66727A", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M45.0620034,73.3964083 C45.0620034,74.1331071 44.4537842,74.7362314 43.7099486,74.7362314 L1.35190068,74.7362314 C0.607037671,74.7362314 -0.000154109589,74.1331071 -0.000154109589,73.3964083 L-0.000154109589,31.3576732 C-0.000154109589,30.6199548 0.607037671,30.0163206 1.35190068,30.0163206 L43.7099486,30.0163206 C44.4537842,30.0163206 45.0620034,30.6199548 45.0620034,31.3576732 L45.0620034,73.3964083 Z", id: "Fill-16", fill: "#FFD859", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M40.7697945,39.8770722 C40.7697945,40.4842751 40.2689384,40.9798262 39.6602055,40.9798262 L29.3307534,40.9798262 C28.7199658,40.9798262 28.2191096,40.4842751 28.2191096,39.8770722 L28.2191096,37.402376 C28.2191096,36.7972124 28.7199658,36.3001319 29.3307534,36.3001319 L39.6602055,36.3001319 C40.2689384,36.3001319 40.7697945,36.7972124 40.7697945,37.402376 L40.7697945,39.8770722 Z", id: "Fill-17", fill: "#66727A", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M0,31.3578772 L0,73.3966122 C0,74.133311 0.607191781,74.7364354 1.35205479,74.7364354 L43.7101027,74.7364354 C44.0820205,74.7364354 44.4200342,74.5855268 44.6635274,74.3423398 L0.399143836,30.4106202 C0.153082192,30.6532974 0,30.9882532 0,31.3578772", id: "Fill-18", fill: "#FFD501", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M31.2438185,67.743252 C31.2438185,68.350455 30.743476,68.8470256 30.1332021,68.8470256 L5.4047774,68.8470256 C4.79296233,68.8470256 4.29313356,68.350455 4.29313356,67.743252 L4.29313356,65.6550041 C4.29313356,65.0478011 4.79296233,64.5512305 5.4047774,64.5512305 L30.1332021,64.5512305 C30.743476,64.5512305 31.2438185,65.0478011 31.2438185,65.6550041 L31.2438185,67.743252 Z", id: "Fill-19", fill: "#FEFEFE", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M31.2438185,61.4102425 C31.2438185,62.0169356 30.743476,62.5135063 30.1332021,62.5135063 L5.4047774,62.5135063 C4.79296233,62.5135063 4.29313356,62.0169356 4.29313356,61.4102425 L4.29313356,59.3235241 C4.29313356,58.716831 4.79296233,58.2197505 5.4047774,58.2197505 L30.1332021,58.2197505 C30.743476,58.2197505 31.2438185,58.716831 31.2438185,59.3235241 L31.2438185,61.4102425 Z", id: "Fill-20", fill: "#FEFEFE", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M20.7204966,52.9568151 C20.7204966,53.3753824 20.3454966,53.7149266 19.8836815,53.7149266 L6.14429795,53.7149266 C5.68196918,53.7149266 5.30696918,53.3753824 5.30696918,52.9568151 L5.30696918,39.0462085 C5.30696918,38.6276412 5.68196918,38.288097 6.14429795,38.288097 L19.8836815,38.288097 C20.3454966,38.288097 20.7204966,38.6276412 20.7204966,39.0462085 L20.7204966,52.9568151 Z", id: "Fill-21", fill: "#FEFEFE", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M19.0961301,53.6493629 L19.0961301,48.2039098 C19.0961301,47.9500164 18.8762671,47.7445564 18.6065753,47.7445564 L7.33756849,47.7445564 C7.06787671,47.7445564 6.8480137,47.9500164 6.8480137,48.2039098 L6.8480137,53.6493629 L19.0961301,53.6493629 Z", id: "Fill-22", fill: "#66727A", mask: "url(#mask-sighting-6)" }),
                        React.createElement("path", { d: "M16.4443664,43.0754156 C16.4443664,41.1870195 14.8986473,39.6565214 12.9923116,39.6565214 C11.0854623,39.6565214 9.54077055,41.1870195 9.54077055,43.0754156 C9.54077055,44.9638116 11.0854623,46.4943098 12.9923116,46.4943098 C14.8986473,46.4943098 16.4443664,44.9638116 16.4443664,43.0754156", id: "Fill-23", fill: "#66727A", mask: "url(#mask-sighting-6)" }),
                        React.createElement("polygon", { id: "Fill-24", fill: "#FEFEFE", mask: "url(#mask-sighting-6)", points: "63.6012842 5.90842627 52.6923801 16.7350944 48.1923801 12.2695272 43.4406678 16.9854191 52.6923801 26.1668783 52.7252568 26.1332297 68.3535103 10.6243182" }))))));
    };
    return DocumentSightingIcon;
}(React.Component));
exports.DocumentSightingIcon = DocumentSightingIcon;
var PadlockIcon = /** @class */ (function (_super) {
    __extends(PadlockIcon, _super);
    function PadlockIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PadlockIcon.prototype.render = function () {
        return (React.createElement("svg", { width: "24px", height: "24px", viewBox: "0 0 18 21" },
            React.createElement("g", { id: "Page-1", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                React.createElement("g", { id: "Filebound-Document-Copy-2", transform: "translate(-114.000000, -937.000000)" },
                    React.createElement("g", { id: "Page-1", transform: "translate(114.000000, 937.000000)" },
                        React.createElement("path", { d: "M14.1057429,13.3038164 C13.6128857,13.3038164 13.2140286,12.9019397 13.2140286,12.4059945 L13.2140286,6.04874795 C13.2140286,3.70336438 11.3191714,1.79552877 8.99002857,1.79552877 C6.6606,1.79552877 4.76602857,3.70336438 4.76602857,6.04874795 L4.76602857,12.4059945 C4.76602857,12.9019397 4.36688571,13.3038164 3.87402857,13.3038164 C3.38202857,13.3038164 2.9826,12.9019397 2.9826,12.4059945 L2.9826,6.04874795 C2.9826,2.71348767 5.67774286,0.000172602739 8.99002857,0.000172602739 C12.3023143,0.000172602739 14.9974571,2.71348767 14.9974571,6.04874795 L14.9974571,12.4059945 C14.9974571,12.9019397 14.5983143,13.3038164 14.1057429,13.3038164 L14.1057429,13.3038164 Z", id: "Fill-1", fill: "#89929C" }),
                        React.createElement("g", { id: "Group-5", transform: "translate(0.000000, 7.207516)" },
                            React.createElement("mask", { id: "padlock-mask-2", fill: "white" },
                                React.createElement("polygon", { id: "path-1", points: "17.9802 13.5205479 0 13.5205479 0 0.237184932 17.9802 0.237184932" })),
                            React.createElement("g", { id: "Clip-4" }),
                            React.createElement("path", { d: "M17.9802,12.2975137 C17.9802,12.9703767 17.4336286,13.5206918 16.7653429,13.5206918 L1.21477143,13.5206918 C0.5462,13.5206918 -8.57142859e-05,12.9703767 -8.57142859e-05,12.2975137 L-8.57142859e-05,1.46036301 C-8.57142859e-05,0.787787671 0.5462,0.237184932 1.21477143,0.237184932 L16.7653429,0.237184932 C17.4336286,0.237184932 17.9802,0.787787671 17.9802,1.46036301 L17.9802,12.2975137 Z", id: "Fill-3", fill: "#66727A", mask: "url(#padlock-mask-2)" })),
                        React.createElement("path", { d: "M11.089,13.1743068 C11.089,14.341389 10.1492857,15.2875397 8.98985714,15.2875397 C7.831,15.2875397 6.89128571,14.341389 6.89128571,13.1743068 C6.89128571,12.006937 7.831,11.0607863 8.98985714,11.0607863 C10.1492857,11.0607863 11.089,12.006937 11.089,13.1743068", id: "Fill-6", fill: "#291C23" }),
                        React.createElement("mask", { id: "padlock-mask-4", fill: "white" },
                            React.createElement("polygon", { id: "path-3", points: "0 20.7280644 17.9802857 20.7280644 17.9802857 -8.63013697e-05 0 -8.63013697e-05" })),
                        React.createElement("g", { id: "Clip-9" }),
                        React.createElement("polygon", { id: "Fill-8", fill: "#291C23", mask: "url(#padlock-mask-4)", points: "8.45142857 17.0706123 9.52885714 17.0706123 9.52885714 13.816763 8.45142857 13.816763" }))))));
    };
    return PadlockIcon;
}(React.Component));
exports.PadlockIcon = PadlockIcon;
var OutageIcon = /** @class */ (function (_super) {
    __extends(OutageIcon, _super);
    function OutageIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    OutageIcon.prototype.render = function () {
        return (React.createElement("svg", { width: "100px", height: "100px", viewBox: "0 0 150 150", style: { display: 'block' } },
            React.createElement("g", { id: "Page-1", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                React.createElement("g", { id: "Forced-app-upgrade", transform: "translate(-113.000000, -130.000000)" },
                    React.createElement("g", { id: "Group-2", transform: "translate(20.000000, 130.000000)" },
                        React.createElement("g", { id: "Page-1", transform: "translate(93.000000, 0.000000)" },
                            React.createElement("g", { id: "Mask", fill: "#8177E7" },
                                React.createElement("rect", { id: "path-1", x: "0", y: "0", width: "150", height: "150", rx: "3" })),
                            React.createElement("g", null,
                                React.createElement("g", { transform: "translate(9.000000, 15.000000)" },
                                    React.createElement("g", { id: "Group-9", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                                        React.createElement("mask", { id: "mask-4", fill: "white" },
                                            React.createElement("polyline", { id: "path-3", points: "0 1.92324638 123.2002 1.92324638 123.2002 286 0 286" })),
                                        React.createElement("g", { id: "Clip-8" })),
                                    React.createElement("g", { id: "Group-16", stroke: "none", strokeWidth: "1", fill: "none", fillRule: "evenodd" },
                                        React.createElement("mask", { id: "mask-6", fill: "white" },
                                            React.createElement("polygon", { id: "path-5", points: "0 1.92324638 123.2002 1.92324638 123.2002 286 0 286" })),
                                        React.createElement("g", { id: "Clip-15" }),
                                        React.createElement("path", { d: "M108.703567,35.3694957 C110.6421,51.5243507 99.0377667,65.8139884 85.1415,72.2552058 C79.9872333,74.6426841 74.2501667,75.9773507 68.2093,75.9773507 C64.2103,75.9773507 60.9635667,79.2290464 60.9635667,83.2454812 C60.9635667,87.2598435 64.2103,90.5136116 68.2093,90.5136116 C98.5314333,90.5136116 123.201233,65.7766841 123.201233,35.3694957 L108.703567,35.3694957 Z", id: "Fill-14", fill: "#FFFFFF" })),
                                    React.createElement("g", { id: "Clip-22", stroke: "none", fill: "none" }),
                                    React.createElement("polygon", { id: "Fill-23", stroke: "none", fill: "#FFFFFF", fillRule: "evenodd", points: "17.4364667 76.0863623 67.3134 76.0863623 67.3134 160.626304 17.4364667 160.626304" }),
                                    React.createElement("polygon", { id: "Fill-24", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd", points: "33.04662 75.8281333 42.3755533 90.4369304 51.70242 75.8281333" }),
                                    React.createElement("polygon", { id: "Fill-25", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd", points: "33.2568 76.0511304 51.3856 76.0511304 51.3856 54.7130435 33.2568 54.7130435" }),
                                    React.createElement("path", { d: "M68.40522,41.9690493 C68.40522,44.7316435 66.17322,46.9699043 63.4162867,46.9699043 C60.6593533,46.9699043 58.4273533,44.7316435 58.4273533,41.9690493 C58.4273533,39.2043826 60.6593533,36.9661217 63.4162867,36.9661217 C66.17322,36.9661217 68.40522,39.2043826 68.40522,41.9690493", id: "Fill-26", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M25.98854,41.9690493 C25.98854,44.7316435 23.7586067,46.9699043 20.99754,46.9699043 C18.2426733,46.9699043 16.0106733,44.7316435 16.0106733,41.9690493 C16.0106733,39.2043826 18.2426733,36.9661217 20.99754,36.9661217 C23.7586067,36.9661217 25.98854,39.2043826 25.98854,41.9690493", id: "Fill-27", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M64.6137133,44.1059667 C64.6137133,56.5138072 54.5800467,66.5776913 42.2048467,66.5776913 C29.83378,66.5776913 19.80218,56.5138072 19.80218,44.1059667 L19.80218,25.7190681 C19.80218,13.3133 29.83378,3.25148841 42.2048467,3.25148841 C54.5800467,3.25148841 64.6137133,13.3133 64.6137133,25.7190681 L64.6137133,44.1059667 Z", id: "Fill-28", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M42.2075333,57.6957333 C40.9220667,57.6957333 39.6407333,57.2066319 38.6632,56.228429 C38.1630667,55.7248203 38.1630667,54.9124145 38.6632,54.4108783 C39.1633333,53.9114145 39.9755333,53.9114145 40.4756667,54.4108783 C41.4346,55.3745739 42.9866667,55.370429 43.9435333,54.4108783 C44.4416,53.9114145 45.2517333,53.9114145 45.7518667,54.4108783 C46.2540667,54.9124145 46.2540667,55.7248203 45.7518667,56.228429 C44.7743333,57.2066319 43.4909333,57.6957333 42.2075333,57.6957333", id: "Fill-29", stroke: "none", fill: "#190B12", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M54.10802,39.9318174 C54.10802,40.7193536 53.4714867,41.3556 52.68822,41.3556 C51.9028867,41.3556 51.2663533,40.7193536 51.2663533,39.9318174 C51.2663533,39.1442812 51.9028867,38.5059623 52.68822,38.5059623 C53.4714867,38.5059623 54.10802,39.1442812 54.10802,39.9318174", id: "Fill-30", stroke: "none", fill: "#190B12", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M33.6329333,39.9318174 C33.6329333,40.7193536 32.9964,41.3556 32.2131333,41.3556 C31.4278,41.3556 30.7912667,40.7193536 30.7912667,39.9318174 C30.7912667,39.1442812 31.4278,38.5059623 32.2131333,38.5059623 C32.9964,38.5059623 33.6329333,39.1442812 33.6329333,39.9318174", id: "Fill-31", stroke: "none", fill: "#190B12", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M47.7590133,33.9881986 C48.5030133,33.9881986 48.5030133,35.0244304 49.24908,35.0244304 C49.99308,35.0244304 49.99308,33.9881986 50.73708,33.9881986 C51.48108,33.9881986 51.48108,35.0244304 52.2230133,35.0244304 C52.9711467,35.0244304 52.9711467,33.9881986 53.7172133,33.9881986 C54.4674133,33.9881986 54.4674133,35.0244304 55.2155467,35.0244304 C55.9657467,35.0244304 55.9657467,33.9881986 56.7159467,33.9881986 C57.4661467,33.9881986 57.4661467,35.0244304 58.2163467,35.0244304", id: "Stroke-32", stroke: "#190B12", strokeWidth: "0.882", strokeLinecap: "round", fill: "none" }),
                                    React.createElement("path", { d: "M26.5419933,33.9881986 C27.28806,33.9881986 27.28806,35.0244304 28.03206,35.0244304 C28.77606,35.0244304 28.77606,33.9881986 29.52006,33.9881986 C30.26406,33.9881986 30.26406,35.0244304 31.00806,35.0244304 C31.7541267,35.0244304 31.7541267,33.9881986 32.50226,33.9881986 C33.2503933,33.9881986 33.2503933,35.0244304 33.9985267,35.0244304 C34.7487267,35.0244304 34.7487267,33.9881986 35.4989267,33.9881986 C36.2491267,33.9881986 36.2491267,35.0244304 36.9993267,35.0244304", id: "Stroke-33", stroke: "#190B12", strokeWidth: "0.882", strokeLinecap: "round", fill: "none" }),
                                    React.createElement("path", { d: "M20.65778,17.4101464 C20.65778,17.4101464 26.1489133,1.5412913 42.94058,2.02624783 C42.94058,2.02624783 59.8955133,-0.736346377 64.41738,22.3446826 L64.41738,42.6693348 L61.5116467,42.6693348 L61.5116467,30.0397406 C61.5116467,30.0397406 56.50618,27.9361899 53.1127133,17.5717986 C53.1127133,17.5717986 47.4624467,33.1152768 22.7575133,30.6863493 L22.7575133,42.5076826 L19.8497133,42.5076826 C19.8497133,36.4022043 17.9752467,22.2949435 20.65778,17.4101464", id: "Fill-34", stroke: "none", fill: "#190B12", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M108.171193,27.9523551 C108.171193,26.2197754 109.535193,24.8146449 111.262927,24.8146449 L119.806527,24.8146449 C121.532193,24.8146449 122.94786,26.2197754 122.94786,27.9523551 L122.94786,32.9221232 C122.94786,34.6547029 121.532193,36.0577609 119.806527,36.0577609 L111.262927,36.0577609 C109.535193,36.0577609 108.171193,34.6547029 108.171193,32.9221232 L108.171193,27.9523551 Z", id: "Fill-35", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M119.567413,23.7398652 C119.567413,22.8010391 120.327947,22.0383725 121.264147,22.0383725 C122.202413,22.0383725 122.94848,22.8010391 122.94848,23.7398652 L122.94848,27.8951551 C122.94848,28.8381261 122.202413,29.5966478 121.264147,29.5966478 C120.327947,29.5966478 119.567413,28.8381261 119.567413,27.8951551 L119.567413,23.7398652 Z", id: "Fill-36", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M108.171193,11.4735739 C108.171193,10.5347478 108.96686,9.77208116 109.905127,9.77208116 C110.841327,9.77208116 111.597727,10.5347478 111.597727,11.4735739 L111.597727,27.8957768 C111.597727,28.8387478 110.841327,29.5951971 109.905127,29.5951971 C108.96686,29.5951971 108.171193,28.8387478 108.171193,27.8957768 L108.171193,11.4735739 Z", id: "Fill-37", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M104.501207,28.197942 C103.988673,27.4145507 104.236673,26.3410145 105.022007,25.8228986 C105.80114,25.311 106.853073,25.5306812 107.365607,26.3140725 L111.829607,33.1386957 C112.346273,33.9262319 112.129273,34.9790435 111.346007,35.4930145 C110.560673,36.0069855 109.479807,35.8121739 108.967273,35.0246377 L104.501207,28.197942 Z", id: "Fill-38", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M109.53044,35.5249304 C110.022307,35.8606696 110.61544,36.0575536 111.262307,36.0575536 L116.637707,36.0575536 C115.74284,32.9136261 112.919773,30.5945391 109.53044,30.4349594 L109.53044,35.5249304 Z", id: "Fill-39", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M88.3874067,86.6509536 C108.75234,78.5642 124.837207,57.7338667 122.948273,32.9223304 L108.62834,34.5056928 C109.529407,52.4159246 99.0390067,65.8123304 85.14274,72.2535478 C84.5062067,72.5499101 83.8614067,72.8276203 83.20834,73.0887507 L88.3874067,86.6509536 Z", id: "Fill-49", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("polygon", { id: "Fill-50", stroke: "none", fill: "#003359", fillRule: "evenodd", points: "31.38502 104.191251 38.0665533 110.891526 31.38502 117.591801 24.7034867 110.891526" }),
                                    React.createElement("polygon", { id: "Fill-51", stroke: "none", fill: "#003359", fillRule: "evenodd", points: "44.2460933 104.191251 50.9276267 110.891526 44.2460933 117.591801 37.56456 110.891526" }),
                                    React.createElement("polygon", { id: "Fill-52", stroke: "none", fill: "#003359", fillRule: "evenodd", points: "57.2301333 104.191251 63.9116667 110.891526 57.2301333 117.591801 50.5486 110.891526" }),
                                    React.createElement("path", { d: "M14.73678,76.0569333 C14.73678,76.0569333 8.62978,76.0569333 8.62978,83.6338609 L8.62978,147.449165 C8.62978,147.449165 8.62978,155.026093 14.73678,155.026093 L17.6900467,155.026093 C17.6900467,155.026093 23.7970467,155.026093 23.7970467,147.449165 L23.7970467,83.6338609 C23.7970467,83.6338609 23.7970467,76.0569333 17.6900467,76.0569333 L14.73678,76.0569333 Z", id: "Fill-58", stroke: "none", fill: "#FFFFFF", fillRule: "evenodd" }),
                                    React.createElement("polygon", { id: "Fill-66", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd", points: "23.4794 110.891319 8.62833333 110.891319 8.62833333 167.061304 23.4794 167.061304" }),
                                    React.createElement("path", { d: "M111.996387,22.0941217 C111.996387,21.1552957 112.754853,20.3905565 113.691053,20.3905565 C114.625187,20.3905565 115.38572,21.1552957 115.38572,22.0941217 L115.38572,27.8970203 C115.38572,28.8379188 114.625187,29.5964406 113.691053,29.5964406 C112.754853,29.5964406 111.996387,28.8379188 111.996387,27.8970203 L111.996387,22.0941217 Z", id: "Fill-67", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" }),
                                    React.createElement("path", { d: "M115.784587,22.0941217 C115.784587,21.1552957 116.540987,20.3905565 117.477187,20.3905565 C118.415453,20.3905565 119.171853,21.1552957 119.171853,22.0941217 L119.171853,27.8970203 C119.171853,28.8379188 118.415453,29.5964406 117.477187,29.5964406 C116.540987,29.5964406 115.784587,28.8379188 115.784587,27.8970203 L115.784587,22.0941217 Z", id: "Fill-68", stroke: "none", fill: "#F3C8AA", fillRule: "evenodd" })))))))));
    };
    return OutageIcon;
}(React.Component));
exports.OutageIcon = OutageIcon;
