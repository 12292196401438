"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * This is the main page after the use logs in
 */
var React = __importStar(require("react"));
var page_1 = require("./base/page");
var ap_react_1 = require("ap-react");
var ap = __importStar(require("ap-react"));
var typestyle = __importStar(require("typestyle"));
var ap_react_2 = require("ap-react");
var csstips = __importStar(require("csstips"));
var typestyle_1 = require("typestyle");
var routes_1 = require("../routing/routes");
/** Landing page config */
var landingPageConfiguration = {
    heroImageUrl: routes_1.images.hero,
    heroImageMediaQueries: typestyle.extend(typestyle.media({ maxWidth: 1036 }, { width: '120%' }), typestyle.media({ maxWidth: 890 }, { width: '150%' }), typestyle.media({ maxWidth: 680 }, { width: '200%' }), typestyle.media({ maxWidth: 500 }, { width: '250%' }), typestyle.media({ maxWidth: 400 }, { width: '350%' })),
    namedImages: {
        width: '280px',
        /** Dependent on the text content in named images */
        height: '380px'
    },
};
var NamedImage = function (_a) {
    var name = _a.name, url = _a.url, additionalText = _a.additionalText;
    return (React.createElement("div", { className: typestyle_1.style(csstips.horizontallyCenterSelf, csstips.horizontallyCenterChildren, {
            backgroundColor: 'white',
            width: landingPageConfiguration.namedImages.width,
            minHeight: landingPageConfiguration.namedImages.height
        }) },
        React.createElement("div", { className: typestyle_1.style({ borderRadius: '3px' }) },
            React.createElement(ap.Content, { className: typestyle_1.style({ backgroundColor: 'lightskyblue' }, csstips.layerParent, csstips.padding(20, 50), { width: landingPageConfiguration.namedImages.width, height: '150px' }) },
                React.createElement("img", { src: url, className: typestyle_1.style(csstips.attachToLayerParent, { bottom: '-20px', left: '50px' }) })),
            React.createElement(ap.SmallVerticalSpace, { space: 40 }),
            React.createElement(ap.Vertical, { className: typestyle_1.style(csstips.padding(20), { textAlign: 'left' }) },
                React.createElement(ap_react_1.Heading3, null, name),
                React.createElement(ap_react_2.LandingPageTextSmaller, null, additionalText)))));
};
exports.Landing = function (_a) {
    // const navToRegister = () => navigate(route.registerInterest());
    return (React.createElement(page_1.Page, { useLandingPageTAndCs: true, seamless: true, sections: [
            React.createElement(ap_react_2.LandingPageHero, { key: "hero", imgUrl: landingPageConfiguration.heroImageUrl, imgMediaClassName: typestyle_1.style(landingPageConfiguration.heroImageMediaQueries) },
                React.createElement(ap_react_2.LandingPageTalky, { moveTalkyOutAtWidth: 1000, position: "right", maxWidth: "410px" },
                    React.createElement(ap_react_2.LandingPageHeadingSecondary, { text: "Secure document" }),
                    React.createElement(ap_react_2.LandingPageHeadingPrimary, { text: "delivery" }),
                    React.createElement(ap.SmallVerticalSpace, null),
                    React.createElement(ap_react_2.LandingPageText, null, "Send important documents to your customers securely online while saving time and money."),
                    React.createElement(ap.SmallVerticalSpace, null),
                    React.createElement(ap.SmallVerticalSpace, { space: 10 }))),
            React.createElement(ap_react_2.LandingPageSection, { key: "keypoints", backgroundColor: ap.Colors.warmGrey1 },
                React.createElement(ap.ContentVerticalCentered, null,
                    React.createElement(ap.Content, { className: typestyle_1.style(csstips.maxWidth(900), csstips.horizontallyCenterChildren) },
                        React.createElement(ap_react_1.Heading3, null, "Let us help you send important digital documents safely and securely.")),
                    React.createElement(ap.SmallVerticalSpace, { space: 40 }),
                    React.createElement(ap.Responsive, { breakpoint: 950 },
                        React.createElement(NamedImage, { name: "Secure", url: routes_1.images.secure, additionalText: "To access, receiver must verify via their mobile or landline phone." }),
                        React.createElement(NamedImage, { name: "Fast", url: routes_1.images.fast, additionalText: "Digital delivery is the most efficient way to reach every client anywhere." }),
                        React.createElement(NamedImage, { name: "Affordable", url: routes_1.images.affordable, additionalText: "Send documents at a fraction of the cost of current physical mail services." })))),
            React.createElement(ap_react_2.LandingPageSection, { key: "focus", backgroundColor: 'white' },
                React.createElement(ap.Responsive, { breakpoint: 750, horizontalModeHorizontalAlign: "center" },
                    React.createElement(ap.VerticalMargined, null,
                        React.createElement(ap_react_1.Heading1, null, "We're focused on keeping your communications safe and secure"),
                        React.createElement(ap_react_2.LandingPageText, null, "Important documents needn't travel the globe via email where they're at risk. Documents sent by Secure Document Delivery are encrypted and stored on our servers here in Australia, helping ensure they stay secure."),
                        React.createElement(ap_react_2.LandingPageText, null, "Your important documents are delivered instantly and we'll tell you when they've been received and opened.")),
                    React.createElement("div", { className: typestyle_1.style(csstips.horizontallyCenterChildren) },
                        React.createElement("img", { src: routes_1.images.focus, style: { maxWidth: '90%', maxHeight: '100%' } })))),
            React.createElement(ap_react_2.LandingPageSection, { key: "how", backgroundColor: ap.Colors.warmGrey1 },
                React.createElement(ap.Responsive, { breakpoint: 750, horizontalModeHorizontalAlign: "center" },
                    React.createElement("div", { className: typestyle_1.style(csstips.horizontallyCenterChildren) },
                        React.createElement("img", { src: routes_1.images.how, className: typestyle_1.style(csstips.maxWidth('100%')) })),
                    React.createElement(ap.VerticalMargined, { className: typestyle_1.style(csstips.padding(0, 24, 0, 24)) },
                        React.createElement(ap_react_1.Heading1, null, "How it works"),
                        React.createElement(ap_react_2.LandingPageText, null, "1. Setup your delivery by entering the receiver's details and attaching your documents."),
                        React.createElement(ap_react_2.LandingPageText, null, "2. Send and pay for your delivery. We'll contact the receiver via email and let them know they have documents waiting."),
                        React.createElement(ap_react_2.LandingPageText, null, "3. To access the documents, the receiver enters a one-off code sent by SMS or phone call. Once they access your documents we'll notify you."),
                        React.createElement(ap_react_2.LandingPageText, null, "Its that simple!")))),
        ] }));
};
