"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * This is the main page after the use logs in
 */
var React = __importStar(require("react"));
var page_1 = require("./base/page");
var ap_react_1 = require("ap-react");
var gls = __importStar(require("ap-react"));
var ap = __importStar(require("ap-react"));
var styles = __importStar(require("ap-react"));
var ap_react_2 = require("ap-react");
var ap_react_3 = require("ap-react");
var ap_react_4 = require("ap-react");
var ap_react_5 = require("ap-react");
var csstips = __importStar(require("csstips"));
var appStepper_1 = require("./components/appStepper");
var ap_react_6 = require("ap-react");
var ap_react_7 = require("ap-react");
var ap_react_8 = require("ap-react");
var sendState_1 = require("../state/sendState");
var ap_react_9 = require("ap-react");
var ap_react_10 = require("ap-react");
var dropzone_1 = require("./components/dropzone");
var ap_react_11 = require("ap-react");
var icons = __importStar(require("ap-react"));
var ap_react_12 = require("ap-react");
var formatters_1 = require("../utils/formatters");
var sendState_2 = require("../state/sendState");
var Send = /** @class */ (function (_super) {
    __extends(Send, _super);
    function Send() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Send.prototype.render = function () {
        var _this = this;
        return (React.createElement(page_1.Page, { seamless: false, sections: [
                React.createElement(ap_react_3.MaxWidthIsland, { key: 'header' },
                    React.createElement(appStepper_1.AppStepper, { current: appStepper_1.AppProcessStep.Send })),
                React.createElement(ap.ListIsland, { key: 'body' },
                    React.createElement(ap.ListIsland_Header, null,
                        React.createElement(gls.Horizontal, { spacing: styles.Spacing.horizontalBetweenFields },
                            React.createElement(ap.ListIsland_Header_Heading, null, "Send"),
                            React.createElement(gls.Flex, null),
                            React.createElement(gls.Content, null, sendState_1.sendState.priceCalculator.calculating
                                ? React.createElement(ap_react_12.Loader, null)
                                : React.createElement(ap.ListIsland_Header_Heading, null,
                                    "Current Cost: $",
                                    React.createElement("span", { id: "totalCost" }, sendState_1.sendState.priceCalculator.price))))),
                    /** Only show the summaries if no transfer is being edited */
                    !!sendState_1.sendState.transferBeingEditied
                        ? this.renderTransferInEditMode(sendState_1.sendState.transferBeingEditied)
                        : sendState_1.sendState.transfers.map(function (transfer, key) {
                            return _this.renderTransferSummary(transfer, key);
                        }),
                    sendState_1.sendState.canAddAnotherRecipientOrContinue && React.createElement(ap.ListIsland_Item, null,
                        React.createElement(gls.Horizontal, { spacing: styles.Spacing.horizontalBetweenFields },
                            React.createElement(gls.Flex, null),
                            React.createElement(ap_react_5.SecondaryButton, { id: "addAnother", onClick: function () { return sendState_1.sendState.addAnotherReceiver(); } }, "Add Another"),
                            React.createElement(ap_react_5.PrimaryButton, { id: "continue", onClick: function () { return sendState_1.sendState.doInitialSend(); } }, "Continue"))),
                    sendState_1.sendState.doingInitalSend && React.createElement(ap.ListIsland_Item, null,
                        React.createElement(gls.FlexHorizontalCentered, null,
                            React.createElement(gls.Flex, null,
                                React.createElement(ap_react_12.ProgressBar, { percent: sendState_1.sendState.currentUpload.overallProgress })),
                            React.createElement(gls.SmallHorizontalSpace, null),
                            React.createElement(ap_react_1.Paragraph, null, "Saving"),
                            React.createElement(gls.SmallHorizontalSpace, null),
                            React.createElement(ap_react_12.Loader, null))))
            ] }));
    };
    Send.prototype.renderTransferSummary = function (transfer, key) {
        var marginForSummary = 10;
        return React.createElement(gls.ContentHorizontalCentered, { style: csstips.padding(24, 40, 10, 24), key: key },
            React.createElement(gls.FlexHorizontalMargined, null,
                React.createElement("div", null,
                    React.createElement(icons.Mail, null)),
                React.createElement(gls.FlexVerticalMargined, { margin: marginForSummary },
                    React.createElement(gls.Horizontal, null,
                        React.createElement(gls.FlexVertical, null,
                            React.createElement(gls.Content, null,
                                React.createElement(ap.Heading5, null,
                                    "To: ",
                                    formatters_1.renderName({
                                        title: transfer.receiver.title.value.value,
                                        firstName: transfer.receiver.firstName.value,
                                        lastName: transfer.receiver.lastName.value,
                                    })),
                                React.createElement(ap.SmallVerticalSpace, { space: 10 })),
                            React.createElement(gls.ContentHorizontal, null,
                                React.createElement(gls.ContentVertical, null,
                                    React.createElement(ap_react_1.Paragraph, null, transfer.receiver.email.value),
                                    React.createElement(ap_react_1.Paragraph, null, transfer.receiver.phone.value)),
                                React.createElement(gls.FlexVerticalCentered, null,
                                    React.createElement(ap_react_1.Ul, null, transfer.files.value.map(function (f, i) {
                                        return React.createElement(gls.Content, { key: i }, f.rawFile.name);
                                    }))))),
                        React.createElement(gls.Vertical, null,
                            React.createElement(gls.Horizontal, null,
                                React.createElement(gls.Content, null,
                                    React.createElement(ap.AnchorButton, { id: "remove" + key, onClick: function () { return sendState_1.sendState.removeTransfer(transfer); } }, "Remove")),
                                React.createElement(gls.Content, null,
                                    React.createElement(ap.ActionAnchorButton, { id: "edit" + key, onClick: function () { return sendState_1.sendState.setAsTransferBeingEdited(transfer); } }, " Edit"))),
                            React.createElement(gls.Content, null, transfer.priceCalculator.calculating
                                ? React.createElement(ap_react_12.Loader, null)
                                : React.createElement(ap_react_1.Paragraph, null,
                                    "Cost: $",
                                    transfer.priceCalculator.price)))),
                    React.createElement(ap_react_2.Hr, null),
                    !!transfer.receiver.customMessage.value.trim() && React.createElement(gls.Vertical, { spacing: marginForSummary },
                        React.createElement(gls.Content, null,
                            React.createElement(ap_react_1.Paragraph, { id: "customMessage" + key }, transfer.receiver.customMessage.value.trim())),
                        React.createElement(ap_react_2.Hr, null)),
                    React.createElement(gls.Content, null,
                        React.createElement(ap_react_1.Paragraph, null,
                            "From: ",
                            formatters_1.renderName({
                                title: transfer.sender.title.value.value,
                                firstName: transfer.sender.firstName.value,
                                lastName: transfer.sender.lastName.value,
                            }))))));
    };
    Send.prototype.renderTransferInEditMode = function (transfer) {
        return (React.createElement(ap.ListIsland_Form, null,
            React.createElement(ap_react_4.Form, { onSubmit: function () {
                    transfer.formState.validate().then(function (res) {
                        if (res.hasError)
                            return;
                        else
                            sendState_1.sendState.commitTransferBeingEdited();
                    });
                } },
                React.createElement(gls.Vertical, null,
                    React.createElement(ap.HeadingWithHr, null,
                        React.createElement(ap_react_1.Heading4, null, "Who is sending?")),
                    transfer.sender.editing
                        ? React.createElement(gls.Vertical, null,
                            React.createElement(ap.MaxWidth, { maxWidth: 140 },
                                React.createElement(ap_react_8.DisplayValueSelectField, { id: "senderTitle", name: "senderTitle", label: "Title", fieldState: transfer.sender.title, values: sendState_1.titles })),
                            React.createElement(gls.Responsive, null,
                                React.createElement(ap_react_6.TextInputField, { id: "senderFirstName", name: "senderFirstName", label: "First Name", star: true, fieldState: transfer.sender.firstName }),
                                React.createElement(ap_react_6.TextInputField, { id: "senderLastName", name: "senderLastName", label: "Last Name", star: true, fieldState: transfer.sender.lastName })),
                            React.createElement(ap_react_6.TextInputField, { id: "senderOrganizationName", label: "Organization", fieldState: transfer.sender.organizationName }),
                            React.createElement(ap_react_6.TextInputField, { id: "senderEmail", name: "senderEmail", label: "Email", star: true, fieldState: transfer.sender.email }))
                        : React.createElement(gls.ContentHorizontal, null,
                            React.createElement(gls.Flex, null,
                                React.createElement(ap_react_1.Paragraph, null,
                                    "From: ",
                                    formatters_1.renderName({
                                        title: transfer.sender.title.value.value,
                                        firstName: transfer.sender.firstName.value,
                                        lastName: transfer.sender.lastName.value,
                                    }),
                                    " (Same as before)")),
                            React.createElement(gls.Content, null,
                                React.createElement(ap.AnchorButton, { onClick: function () { return transfer.sender.setEditing(true); } }, "Edit"))),
                    React.createElement(ap.SmallVerticalSpace, { space: 10 }),
                    React.createElement(gls.Vertical, null,
                        React.createElement(ap.HeadingWithHr, null,
                            React.createElement(ap_react_1.Heading4, null, "Who are you sending to?")),
                        React.createElement(ap.MaxWidth, { maxWidth: 140 },
                            React.createElement(ap_react_8.DisplayValueSelectField, { id: "receiverTitle", name: "receiverTitle", label: "Title", fieldState: transfer.receiver.title, values: sendState_1.titles })),
                        React.createElement(gls.Responsive, { breakpoint: styles.Breakpoints.sm },
                            React.createElement(ap_react_6.TextInputField, { id: "receiverFirstName", name: "receiverFirstName", label: "First Name", star: true, fieldState: transfer.receiver.firstName }),
                            React.createElement(ap_react_6.TextInputField, { id: "receiverLastName", name: "receiverLastName", label: "Last Name", star: true, fieldState: transfer.receiver.lastName })),
                        React.createElement(gls.Responsive, { breakpoint: styles.Breakpoints.sm },
                            React.createElement(ap_react_6.TextInputField, { id: "receiverEmail", name: "receiverEmail", label: "Email", star: true, fieldState: transfer.receiver.email }),
                            React.createElement(ap_react_6.TextInputField, { id: "receiverPhone", name: "receiverPhone", label: "Phone (mobile or local / int'l landline)", star: true, fieldState: transfer.receiver.phone })),
                        React.createElement(ap_react_6.TextAreaField, { id: "customMessage", name: "customMessage", label: "Optional Message (50 words max)", star: false, fieldState: transfer.receiver.customMessage, tooltip: React.createElement(ap.Tooltip, null,
                                React.createElement(ap.Paragraph, null, "This message will appear on the recipient\u2019s notification email and allows you to add an optional message with your secure document(s).")) })),
                    React.createElement(ap.SmallVerticalSpace, { space: 10 }),
                    React.createElement(gls.Vertical, null,
                        React.createElement(ap.HeadingWithHr, null,
                            React.createElement(ap_react_1.Heading4, null, "Add your documents here")),
                        !!transfer.files.value.length && React.createElement(ap_react_1.Ul, null, transfer.files.value.map(function (f, i) {
                            return React.createElement(gls.ContentHorizontal, { key: i },
                                React.createElement(gls.Flex, null,
                                    "\u2022 ",
                                    f.rawFile.name),
                                React.createElement(gls.Content, null,
                                    React.createElement(ap.AnchorButton, { id: "removeFile" + i, onClick: function () { return sendState_1.sendState.removeFile(transfer, f); } }, "Remove")));
                        })),
                        transfer.files.hasError && React.createElement(ap_react_7.Alert, { id: "filesError", type: ap_react_7.AlertType.Error }, transfer.files.error),
                        (transfer.files.value.length < (sendState_2.maximumAllowedFiles + 1)) && React.createElement(dropzone_1.SingleFileDropzone, { id: "dropzone", validators: [
                                function (file) {
                                    if (!sendState_1.allowedFileExtensions.some(function (ext) { return file.name.toLowerCase().endsWith('.' + ext); })) {
                                        return "Must be an image file: (" + sendState_1.allowedFileExtensions.join(', ') + ")";
                                    }
                                }
                            ], contents: React.createElement("button", { type: "button", className: ap_react_11.ButtonStyles.secondaryClassName },
                                "Add ",
                                !transfer.files.value.length ? 'a ' : 'another ',
                                "document"), onFileAdded: function (file) {
                                sendState_1.sendState.addFile(transfer, new sendState_1.FileState(file));
                            } })),
                    React.createElement(gls.Content, null, transfer.priceCalculator.calculating
                        ? React.createElement(ap_react_12.Loader, null)
                        : React.createElement(ap_react_1.Paragraph, null,
                            "Current Cost: $",
                            React.createElement("span", { id: "currentCost" }, sendState_1.sendState.priceCalculator.price))),
                    React.createElement(ap_react_2.Hr, null),
                    React.createElement(gls.Horizontal, { spacing: styles.Spacing.horizontalBetweenFields },
                        React.createElement(gls.Flex, null),
                        sendState_1.sendState.canDeleteTransferBeingEdited && React.createElement(ap_react_5.SecondaryButton, { onClick: function () {
                                sendState_1.sendState.deleteTransferBeingEdited();
                            } }, "Cancel"),
                        React.createElement(ap_react_10.SubmitInputPrimary, { id: "save", value: transfer.mode === 'edit' ? "Save" : "Continue", disabled: transfer.formState.hasError, loading: transfer.formState.validating }))))));
    };
    Send = __decorate([
        ap_react_9.observer
    ], Send);
    return Send;
}(React.Component));
exports.Send = Send;
