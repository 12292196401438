"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var ap_react_1 = require("ap-react");
var ap_react_2 = require("ap-react");
var mobx_1 = require("mobx");
var config_1 = require("../config/config");
var sd2Controller = __importStar(require("../services/sd2Controller"));
var sd2DocumentManager = __importStar(require("../services/sd2DocumentManager"));
var dropzone_1 = require("../pages/components/dropzone");
var routes_1 = require("../routing/routes");
var validators_1 = require("./validators");
var yester_1 = require("yester");
exports.maximumAllowedFiles = 4;
var noTitle = { display: 'None', value: '' };
exports.titles = [noTitle]
    .concat([
    'Mr',
    'Mrs',
    'Miss',
    'Ms',
    'Dr'
].map(function (key) { return ({ display: key, value: key }); }));
exports.allowedFileExtensions = [
    'jpg',
    'jpeg',
    'gif',
    'png',
    'bmp',
    /** docs */
    'pdf',
];
var PriceCalculator = /** @class */ (function () {
    function PriceCalculator(getDocumentCounts) {
        this.getDocumentCounts = getDocumentCounts;
        this.reset();
    }
    PriceCalculator.prototype.reset = function () {
        this.price = "0.00";
        this.calculating = false;
        this.documentCounts = [];
    };
    PriceCalculator.prototype.recalculate = function () {
        var _this = this;
        var documentCounts = this.getDocumentCounts();
        this.calculating = true;
        this.documentCounts = documentCounts;
        sd2Controller
            .calculateDocumentPrice({ documentCounts: documentCounts })
            .then(function (res) {
            _this.price = res.totalPrice;
            _this.calculating = false;
        });
    };
    Object.defineProperty(PriceCalculator.prototype, "gst", {
        get: function () {
            var gst = (parseFloat(this.price) / 11).toFixed(2);
            return gst;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PriceCalculator.prototype, "totalDocumentsCount", {
        get: function () {
            return this.documentCounts.reduce(function (a, b) { return a + b; }, 0);
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        mobx_1.observable
    ], PriceCalculator.prototype, "calculating", void 0);
    __decorate([
        mobx_1.observable
    ], PriceCalculator.prototype, "price", void 0);
    __decorate([
        mobx_1.observable
    ], PriceCalculator.prototype, "documentCounts", void 0);
    __decorate([
        mobx_1.action
    ], PriceCalculator.prototype, "reset", null);
    __decorate([
        mobx_1.action
    ], PriceCalculator.prototype, "recalculate", null);
    __decorate([
        mobx_1.computed
    ], PriceCalculator.prototype, "gst", null);
    __decorate([
        mobx_1.computed
    ], PriceCalculator.prototype, "totalDocumentsCount", null);
    return PriceCalculator;
}());
var defaultValues = {
    senderFirstName: '',
    senderLastName: '',
    senderEmail: '',
    organizationName: '',
    receiverFirstName: '',
    receiverLastName: '',
    receiverEmail: '',
    receiverPhone: '',
    receiverCustomMessage: '',
    defaultCreditCard: null
};
var createDefaultTransfers = function () { return [new TransferState()]; };
if (config_1.config.debug) {
    defaultValues = {
        senderFirstName: 'John',
        senderLastName: 'Dolton',
        senderEmail: 'sender@example.com',
        organizationName: '',
        receiverFirstName: 'David',
        receiverLastName: 'Houser',
        receiverEmail: 'receiver@example.com',
        receiverPhone: '0400123456',
        receiverCustomMessage: 'We have your test results available, please read through ' +
            'them and contact the clinic on 9432 3242 for a follow up appointments',
        defaultCreditCard: {
            last4: '3456',
            scheme: 'visa',
            token: '',
        }
    };
    // createDefaultTransfers = () => {
    //   const defaultTransfer = new TransferState();
    //   return [defaultTransfer, new TransferState()];
    // }
}
var FileWithTransfer = /** @class */ (function () {
    function FileWithTransfer(file, transfer) {
        this.file = file;
        this.transfer = transfer;
    }
    return FileWithTransfer;
}());
;
/**
 * Abstracts away the heavy lifting of maintaing a list of pending + overall progress of an upload
 * Uploads files in sequence one by one
 */
var BulkFileUploader = /** @class */ (function () {
    function BulkFileUploader() {
        this.total = [];
        this.pending = [];
        this.done = [];
        this.failed = [];
        this.progressForCurrentFile = 0;
    }
    BulkFileUploader.prototype.clear = function () {
        this.total = [];
        this.pending = [];
        this.done = [];
        this.failed = [];
        this.progressForCurrentFile = 0;
    };
    BulkFileUploader.prototype.start = function (config) {
        return __awaiter(this, void 0, void 0, function () {
            var uploadNext;
            var _this = this;
            return __generator(this, function (_a) {
                this.total = config.total.slice();
                this.pending = config.total.slice();
                this.done = [];
                this.failed = [];
                this.progressForCurrentFile = 0;
                if (!this.total.length)
                    return [2 /*return*/];
                uploadNext = function (t) { return __awaiter(_this, void 0, void 0, function () {
                    var uploadConfig, file, headers, url;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, config.getUploadFileConfig(t)];
                            case 1:
                                uploadConfig = _a.sent();
                                file = uploadConfig.file, headers = uploadConfig.headers, url = uploadConfig.url;
                                config.onProgress(t, 0);
                                this.progressForCurrentFile = 0;
                                /** The upload */
                                dropzone_1.uploadFile({
                                    file: file,
                                    headers: headers,
                                    url: url,
                                    onFail: function () {
                                        config.onFail(t);
                                        _this.failed.push(t);
                                        _this.pending = _this.pending.filter(function (p) { return p !== t; });
                                    },
                                    onProgress: function (num) {
                                        config.onProgress(t, num);
                                        _this.progressForCurrentFile = num;
                                    },
                                    onSuccess: function () {
                                        config.onSuccess(t);
                                        _this.done.push(t);
                                        _this.pending = _this.pending.filter(function (p) { return p !== t; });
                                        if (_this.pending.length) {
                                            uploadNext(_this.pending[0]);
                                        }
                                        else {
                                            config.onOverallSuccess();
                                        }
                                    }
                                });
                                return [2 /*return*/];
                        }
                    });
                }); };
                uploadNext(this.pending[0]);
                return [2 /*return*/];
            });
        });
    };
    Object.defineProperty(BulkFileUploader.prototype, "overallProgress", {
        /**
         * Overall progress
         */
        get: function () {
            var total = this.total.length;
            if (total == 0)
                return 0;
            var eachFileGets = 1 / total;
            return this.done.length / total * 100 + (eachFileGets * this.progressForCurrentFile);
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        mobx_1.observable
    ], BulkFileUploader.prototype, "total", void 0);
    __decorate([
        mobx_1.observable
    ], BulkFileUploader.prototype, "pending", void 0);
    __decorate([
        mobx_1.observable
    ], BulkFileUploader.prototype, "done", void 0);
    __decorate([
        mobx_1.observable
    ], BulkFileUploader.prototype, "failed", void 0);
    __decorate([
        mobx_1.observable
    ], BulkFileUploader.prototype, "progressForCurrentFile", void 0);
    __decorate([
        mobx_1.action
    ], BulkFileUploader.prototype, "clear", null);
    __decorate([
        mobx_1.action
    ], BulkFileUploader.prototype, "start", null);
    __decorate([
        mobx_1.computed
    ], BulkFileUploader.prototype, "overallProgress", null);
    return BulkFileUploader;
}());
var FileState = /** @class */ (function () {
    function FileState(file) {
        this.uploadStatus = "local";
        this.uploadProgress = 0;
        /** Once the file is being preped for upload */
        this.documentId = '';
        this.rawFile = file;
    }
    __decorate([
        mobx_1.observable
    ], FileState.prototype, "rawFile", void 0);
    __decorate([
        mobx_1.observable
    ], FileState.prototype, "uploadStatus", void 0);
    __decorate([
        mobx_1.observable
    ], FileState.prototype, "uploadProgress", void 0);
    __decorate([
        mobx_1.observable
    ], FileState.prototype, "documentId", void 0);
    return FileState;
}());
exports.FileState = FileState;
var ReceiverState = /** @class */ (function () {
    function ReceiverState() {
        this.title = new ap_react_1.FieldState(noTitle);
        this.firstName = new ap_react_1.FieldState(defaultValues.receiverFirstName).validators(ap_react_2.validation.required);
        this.lastName = new ap_react_1.FieldState(defaultValues.receiverLastName).validators(ap_react_2.validation.required);
        this.email = new ap_react_1.FieldState(defaultValues.receiverEmail).validators(ap_react_2.validation.required, ap_react_2.validation.email);
        this.phone = new ap_react_1.FieldState(defaultValues.receiverPhone).validators(ap_react_2.validation.required, validators_1.phoneNumberValidator);
        this.customMessage = new ap_react_1.FieldState(defaultValues.receiverCustomMessage).
            validators(function (val) { return val.split(/\s/).length > 50 && 'Must be less than 50 words'; });
    }
    __decorate([
        mobx_1.observable
    ], ReceiverState.prototype, "title", void 0);
    __decorate([
        mobx_1.observable
    ], ReceiverState.prototype, "firstName", void 0);
    __decorate([
        mobx_1.observable
    ], ReceiverState.prototype, "lastName", void 0);
    __decorate([
        mobx_1.observable
    ], ReceiverState.prototype, "email", void 0);
    __decorate([
        mobx_1.observable
    ], ReceiverState.prototype, "phone", void 0);
    __decorate([
        mobx_1.observable
    ], ReceiverState.prototype, "customMessage", void 0);
    return ReceiverState;
}());
exports.ReceiverState = ReceiverState;
var SenderState = /** @class */ (function () {
    function SenderState() {
        /**
         * Sender
         */
        this.title = new ap_react_1.FieldState(noTitle);
        this.firstName = new ap_react_1.FieldState(defaultValues.senderFirstName).validators(ap_react_2.validation.required);
        this.lastName = new ap_react_1.FieldState(defaultValues.senderLastName).validators(ap_react_2.validation.required);
        this.email = new ap_react_1.FieldState(defaultValues.senderEmail).validators(ap_react_2.validation.required, ap_react_2.validation.email);
        this.organizationName = new ap_react_1.FieldState(defaultValues.organizationName);
        this.editing = true;
    }
    SenderState.prototype.setEditing = function (editing) {
        this.editing = editing;
    };
    __decorate([
        mobx_1.observable
    ], SenderState.prototype, "title", void 0);
    __decorate([
        mobx_1.observable
    ], SenderState.prototype, "firstName", void 0);
    __decorate([
        mobx_1.observable
    ], SenderState.prototype, "lastName", void 0);
    __decorate([
        mobx_1.observable
    ], SenderState.prototype, "email", void 0);
    __decorate([
        mobx_1.observable
    ], SenderState.prototype, "organizationName", void 0);
    __decorate([
        mobx_1.observable
    ], SenderState.prototype, "editing", void 0);
    __decorate([
        mobx_1.action
    ], SenderState.prototype, "setEditing", null);
    return SenderState;
}());
var TransferState = /** @class */ (function () {
    function TransferState() {
        var _this = this;
        this.sender = new SenderState();
        this.receiver = new ReceiverState();
        this.files = new ap_react_1.FieldState([]).onUpdate(function (state) {
            _this.files = state;
            /** If value is settled then recalculate price */
            if (state.value.length > 0)
                _this.priceCalculator.recalculate();
        }).validators(function (files) {
            return files.length > exports.maximumAllowedFiles
                ? "Maximum of " + exports.maximumAllowedFiles + " files allowed"
                : !files.length
                    ? "You must add at least one document"
                    : undefined;
        });
        /** Field validation */
        this.formState = new ap_react_1.FormState({
            /** sender */
            senderTitle: this.sender.title,
            senderFirstName: this.sender.firstName,
            senderLastName: this.sender.lastName,
            senderEmail: this.sender.email,
            /** receiver */
            receiverTitle: this.receiver.title,
            receiverFirstName: this.receiver.firstName,
            receiverLastName: this.receiver.lastName,
            receiverEmail: this.receiver.email,
            receiverPhone: this.receiver.phone,
            receiverCustomMessage: this.receiver.customMessage,
            /** File */
            receiverFiles: this.files,
        });
        this.priceCalculator = new PriceCalculator(function () { return ([_this.files.value.length]); });
        this.mode = 'add';
    }
    TransferState.prototype.setMode = function (mode) {
        this.mode = mode;
    };
    __decorate([
        mobx_1.observable
    ], TransferState.prototype, "transferId", void 0);
    __decorate([
        mobx_1.observable
    ], TransferState.prototype, "sender", void 0);
    __decorate([
        mobx_1.observable
    ], TransferState.prototype, "receiver", void 0);
    __decorate([
        mobx_1.observable
    ], TransferState.prototype, "files", void 0);
    __decorate([
        mobx_1.observable
    ], TransferState.prototype, "formState", void 0);
    __decorate([
        mobx_1.observable
    ], TransferState.prototype, "priceCalculator", void 0);
    __decorate([
        mobx_1.observable
    ], TransferState.prototype, "mode", void 0);
    __decorate([
        mobx_1.action
    ], TransferState.prototype, "setMode", null);
    return TransferState;
}());
exports.TransferState = TransferState;
/**
 * Payhive card state
 */
var CreditCardState = /** @class */ (function () {
    function CreditCardState() {
        this.payhiveCreditCard = defaultValues.defaultCreditCard;
        this.tokenizing = false;
        this.paymentFailureMessage = null;
        this.tokenizeFailureMessage = null;
    }
    CreditCardState.prototype.setTokenizing = function (tokenizing) {
        this.tokenizing = tokenizing;
        this.clearCreditCard();
    };
    CreditCardState.prototype.setCreditCard = function (creditCard) {
        this.paymentFailureMessage = null;
        this.payhiveCreditCard = creditCard;
    };
    CreditCardState.prototype.clearCreditCard = function (tokenizeFailureMessage) {
        this.paymentFailureMessage = tokenizeFailureMessage;
        this.payhiveCreditCard = null;
    };
    Object.defineProperty(CreditCardState.prototype, "isPaymentDisabled", {
        get: function () {
            return (this.payhiveCreditCard == null);
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        mobx_1.observable
    ], CreditCardState.prototype, "payhiveCreditCard", void 0);
    __decorate([
        mobx_1.observable
    ], CreditCardState.prototype, "tokenizing", void 0);
    __decorate([
        mobx_1.observable
    ], CreditCardState.prototype, "paymentFailureMessage", void 0);
    __decorate([
        mobx_1.action
    ], CreditCardState.prototype, "setTokenizing", null);
    __decorate([
        mobx_1.observable
    ], CreditCardState.prototype, "tokenizeFailureMessage", void 0);
    __decorate([
        mobx_1.action
    ], CreditCardState.prototype, "setCreditCard", null);
    __decorate([
        mobx_1.action
    ], CreditCardState.prototype, "clearCreditCard", null);
    __decorate([
        mobx_1.computed
    ], CreditCardState.prototype, "isPaymentDisabled", null);
    return CreditCardState;
}());
var SendState = /** @class */ (function () {
    function SendState() {
        var _this = this;
        /**
         * Transfers
         */
        this.transfers = [];
        /**
         * Only one transfer can be in edit mode. If any
         */
        this.transferBeingEditied = undefined;
        /**
         * Credit card state
         */
        this.creditCard = new CreditCardState();
        /**
         * Terms and conditions acceptance
         */
        this.termsAndConditions = new ap_react_1.FieldState(false).validators(function (value) { return !value && "You must accept the terms and conditions"; });
        this.priceCalculator = new PriceCalculator(function () { return _this.transfers.map(function (t) { return t.files.value.length; }); });
        this.doingFinalSend = false;
        this.reset();
    }
    SendState.prototype.reset = function () {
        this.transfers = createDefaultTransfers();
        this.transferBeingEditied = this.transfers[this.transfers.length - 1];
        this.creditCard = new CreditCardState();
        this.doingFinalSend = false;
        this.doingInitalSend = false;
        this.initialSendError = '';
        this.currentUpload = new BulkFileUploader();
        this.priceCalculator.reset();
        this.termsAndConditions.reset(false);
    };
    SendState.prototype.addAnotherReceiver = function () {
        /** Create */
        var newTransfer = new TransferState();
        /** Copy sender */
        var lastTransfer = this.transfers[this.transfers.length - 1];
        if (lastTransfer) {
            newTransfer.sender.title.reset(lastTransfer.sender.title.value);
            newTransfer.sender.firstName.reset(lastTransfer.sender.firstName.value);
            newTransfer.sender.lastName.reset(lastTransfer.sender.lastName.value);
            newTransfer.sender.email.reset(lastTransfer.sender.email.value);
            newTransfer.sender.organizationName.reset(lastTransfer.sender.organizationName.value);
            newTransfer.sender.editing = false;
        }
        /** Add */
        this.transfers.push(newTransfer);
        /** Set as editing */
        this.transferBeingEditied = newTransfer;
    };
    Object.defineProperty(SendState.prototype, "canAddAnotherRecipientOrContinue", {
        get: function () {
            return !this.transferBeingEditied && !this.doingInitalSend;
        },
        enumerable: true,
        configurable: true
    });
    SendState.prototype.commitTransferBeingEdited = function () {
        this.transferBeingEditied = undefined;
    };
    Object.defineProperty(SendState.prototype, "canDeleteTransferBeingEdited", {
        get: function () {
            return this.transfers.length > 1 && (this.transferBeingEditied && this.transferBeingEditied.mode !== 'edit');
        },
        enumerable: true,
        configurable: true
    });
    SendState.prototype.deleteTransferBeingEdited = function () {
        var _this = this;
        this.transfers = this.transfers.filter(function (t) { return t !== _this.transferBeingEditied; });
        this.transferBeingEditied = undefined;
    };
    SendState.prototype.removeTransfer = function (transfer) {
        this.transfers = this.transfers.filter(function (t) { return t !== transfer; });
        this.priceCalculator.recalculate();
        /** If no transfers. Reset! */
        if (!this.transfers.length) {
            this.transfers = createDefaultTransfers();
            this.transferBeingEditied = this.transfers[this.transfers.length - 1];
        }
    };
    SendState.prototype.setAsTransferBeingEdited = function (transfer) {
        this.transferBeingEditied = transfer;
        transfer.setMode('edit');
    };
    SendState.prototype.addFile = function (transfer, file) {
        transfer.files.onChange(transfer.files.value.concat([file]));
        this.priceCalculator.recalculate();
    };
    SendState.prototype.removeFile = function (transfer, file) {
        transfer.files.onChange(transfer.files.value.filter(function (f) { return f !== file; }));
        this.priceCalculator.recalculate();
    };
    Object.defineProperty(SendState.prototype, "isPaymentDisabled", {
        get: function () {
            return this.creditCard.isPaymentDisabled || this.termsAndConditions.hasError;
        },
        enumerable: true,
        configurable: true
    });
    SendState.prototype.doInitialSend = function () {
        return __awaiter(this, void 0, void 0, function () {
            var initializeTransferRes, transactionId_1, transferPromises, transferResults_1, total_1, result, ex_1;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.doingInitalSend = true;
                        this.initialSendError = '';
                        this.currentUpload.clear();
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, sd2Controller.initialiseTransaction()];
                    case 2:
                        initializeTransferRes = _a.sent();
                        transactionId_1 = initializeTransferRes.transactionId;
                        this.transactionId = transactionId_1;
                        transferPromises = this.transfers.map(function (t) { return sd2Controller.initialiseTransfer({ transactionId: _this.transactionId }); });
                        return [4 /*yield*/, Promise.all(transferPromises)];
                    case 3:
                        transferResults_1 = _a.sent();
                        this.transfers.forEach(function (t, i) {
                            t.transferId = transferResults_1[i].transferId;
                        });
                        total_1 = [];
                        this.transfers.forEach(function (t) { return t.files.value.forEach(function (f) {
                            total_1.push(new FileWithTransfer(f, t));
                        }); });
                        result = this.currentUpload.start({
                            total: total_1,
                            getUploadFileConfig: function (info) {
                                return sd2DocumentManager
                                    .uploadUrl({
                                    transactionId: transactionId_1,
                                    transferId: info.transfer.transferId,
                                    contentLength: info.file.rawFile.size,
                                    contentType: info.file.rawFile.type
                                })
                                    .then(function (res) {
                                    info.file.documentId = res.documentId;
                                    return { file: info.file.rawFile, headers: res.headers, url: res.url };
                                });
                            },
                            onFail: function (info) {
                                info.file.uploadStatus = 'failed';
                            },
                            onProgress: function (info, progress) {
                                info.file.uploadStatus = 'uploading';
                                info.file.uploadProgress = progress;
                            },
                            onSuccess: function (info) {
                                info.file.uploadStatus = 'uploaded';
                            },
                            onOverallSuccess: function () {
                                yester_1.navigate(routes_1.route.payment(), true);
                            }
                        });
                        return [3 /*break*/, 5];
                    case 4:
                        ex_1 = _a.sent();
                        this.initialSendError = ex_1.message;
                        this.doingInitalSend = false;
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    SendState.prototype.doFinalSend = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tAndCValidation, transfers, processTransactionQuery, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.termsAndConditions.validate()];
                    case 1:
                        tAndCValidation = _a.sent();
                        if (tAndCValidation.hasError)
                            return [2 /*return*/];
                        this.doingFinalSend = true;
                        transfers = this.transfers.map(function (transfer) {
                            var sender = {
                                title: transfer.sender.title.$.value,
                                firstName: transfer.sender.firstName.$,
                                lastName: transfer.sender.lastName.$,
                                email: transfer.sender.email.$,
                                organizationName: transfer.sender.organizationName.$.trim(),
                            };
                            var receiver = {
                                title: transfer.receiver.title.$.value,
                                firstName: transfer.receiver.firstName.$,
                                lastName: transfer.receiver.lastName.$,
                                email: transfer.receiver.email.$,
                                phone: transfer.receiver.phone.$
                            };
                            var documents = transfer.files.$.map(function (file) {
                                var result = {
                                    documentId: file.documentId,
                                    name: file.rawFile.name,
                                    type: file.rawFile.type
                                };
                                return result;
                            });
                            var result = {
                                transferId: transfer.transferId,
                                customMessage: transfer.receiver.customMessage.$,
                                sender: sender,
                                receiver: receiver,
                                documents: documents,
                            };
                            return result;
                        });
                        processTransactionQuery = {
                            transactionId: this.transactionId,
                            payhiveCardToken: this.creditCard.payhiveCreditCard.token,
                            transfers: transfers,
                            /** Payer defaults to the first sender for now. We will allow UI customization of this later */
                            payer: transfers[0].sender
                        };
                        return [4 /*yield*/, sd2Controller.processTransaction(processTransactionQuery)];
                    case 2:
                        res = _a.sent();
                        if (res.status === 'SUCCESS') {
                            yester_1.navigate(routes_1.route.success(), true);
                        }
                        else if (res.status === 'FAILURE') {
                            this.creditCard.paymentFailureMessage = res.message;
                        }
                        else {
                            yester_1.navigate(routes_1.route.error());
                        }
                        this.doingFinalSend = false;
                        console.log("Response : " + JSON.stringify(res, null, 4));
                        return [2 /*return*/];
                }
            });
        });
    };
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "transactionId", void 0);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "transfers", void 0);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "transferBeingEditied", void 0);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "creditCard", void 0);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "termsAndConditions", void 0);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "reset", null);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "addAnotherReceiver", null);
    __decorate([
        mobx_1.computed
    ], SendState.prototype, "canAddAnotherRecipientOrContinue", null);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "commitTransferBeingEdited", null);
    __decorate([
        mobx_1.computed
    ], SendState.prototype, "canDeleteTransferBeingEdited", null);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "deleteTransferBeingEdited", null);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "removeTransfer", null);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "setAsTransferBeingEdited", null);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "addFile", null);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "removeFile", null);
    __decorate([
        mobx_1.computed
    ], SendState.prototype, "isPaymentDisabled", null);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "priceCalculator", void 0);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "doingInitalSend", void 0);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "initialSendError", void 0);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "currentUpload", void 0);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "doInitialSend", null);
    __decorate([
        mobx_1.observable
    ], SendState.prototype, "doingFinalSend", void 0);
    __decorate([
        mobx_1.action
    ], SendState.prototype, "doFinalSend", null);
    return SendState;
}());
exports.sendState = new SendState();
