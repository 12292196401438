"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * The page shown on a successful outcome
 */
var ap = __importStar(require("ap-react"));
var ap_react_1 = require("ap-react");
var React = __importStar(require("react"));
var receiveState_1 = require("../state/receiveState");
var page_1 = require("./base/page");
var receiveMfaPinInfoSection_1 = require("./receiveMfaPinInfoSection");
var receiveMfaPinSentSection_1 = require("./receiveMfaPinSentSection");
var ReceiveMfaPage = /** @class */ (function (_super) {
    __extends(ReceiveMfaPage, _super);
    function ReceiveMfaPage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {};
        return _this;
    }
    ReceiveMfaPage.prototype.render = function () {
        return (receiveState_1.receiveState.infoRetrieved
            ?
                React.createElement(page_1.Page, { seamless: false, sections: [
                        React.createElement(ap_react_1.DialogHeadingReef, { extraSpaceOnTop: true },
                            React.createElement(ap_react_1.Heading3, null, "Welcome" + (receiveState_1.receiveState.displayName ? ' ' + receiveState_1.receiveState.displayName : ''))),
                        React.createElement(ap_react_1.DialogIsland, null, receiveState_1.receiveState.catastrophicErrorMessage
                            ?
                                React.createElement(ap_react_1.Alert, { type: ap_react_1.AlertType.Error }, receiveState_1.receiveState.catastrophicErrorMessage)
                            :
                                React.createElement(ap.Vertical, null,
                                    receiveState_1.receiveState.recoverableErrorMessage
                                        && React.createElement(ap_react_1.Alert, { type: ap_react_1.AlertType.Error }, receiveState_1.receiveState.recoverableErrorMessage),
                                    receiveState_1.receiveState.showPinSentSection
                                        ? React.createElement(receiveMfaPinSentSection_1.ReceiveMfaPinSentSection, { params: this.props.params })
                                        : React.createElement(receiveMfaPinInfoSection_1.ReceiveMfaPinInfoSection, { params: this.props.params })))
                    ] })
            :
                React.createElement(page_1.Page, { seamless: false, sections: [
                        React.createElement(ap_react_1.DialogHeadingReef, { extraSpaceOnTop: true },
                            React.createElement(ap_react_1.Heading3, null, "\u00A0")),
                        React.createElement(ap_react_1.DialogIsland, null,
                            React.createElement(ap_react_1.SectionLoader, null))
                    ] }));
    };
    ReceiveMfaPage = __decorate([
        ap_react_1.observer
    ], ReceiveMfaPage);
    return ReceiveMfaPage;
}(React.Component));
exports.ReceiveMfaPage = ReceiveMfaPage;
