"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/** `000000` | `000 000` */
exports.pinCodeValidator = function (val) {
    if (val && ((val.replace(/\s*/g, '')).match(/^([0-9]{6})$/g) == null)) {
        return "Must be 6 digits";
    }
};
/** Validate phone number using google libphonenumber library */
var phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance();
exports.phoneNumberValidator = function (phoneNumber) {
    try {
        var parsedPhoneNumber = phoneNumber.charAt(0) === "+" ? phoneUtil.parse(phoneNumber) : phoneUtil.parse(phoneNumber, 'AU');
        if (!phoneUtil.isValidNumber(parsedPhoneNumber)) {
            return "Invalid phone number";
        }
    }
    catch (err) {
        return "Invalid phone number";
    }
};
