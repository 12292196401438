"use strict";
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var ap = __importStar(require("ap-react"));
var Dates;
(function (Dates) {
    /** 12 October 2016 */
    function formatDatePaid(date) {
        return ap.moment(date).format('D MMMM YYYY');
    }
    Dates.formatDatePaid = formatDatePaid;
})(Dates = exports.Dates || (exports.Dates = {}));
/** visa => Visa */
exports.upperCaseFirstLetter = function (str) { return str ? str[0].toUpperCase() + str.substr(1) : ''; };
exports.renderName = function (details) {
    return [
        (details.title ? details.title : ''),
        (details.firstName ? details.firstName : ''),
        (details.lastName ? details.lastName : ''),
    ].filter(function (x) { return !!x; }).join(' ');
};
/** adds an `s` if needed */
exports.s = function (num) { return num > 1 ? 's' : ''; };
exports.removeAllWhiteSpace = function (s) {
    return s.replace(/\s*/g, '');
};
